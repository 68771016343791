export const COMPETITION_DETAIL = [
  {
    title: '简介',
    content: [
      '国家“十四五”规划和《中国教育现代化2035》中强调，建设教育强国，用科技手段、新一代信息技术以及多方社会资源，开展教育新生态的尝试，健全协同育人机制。',
    ],
  },
  {
    title: '参赛条件',
    content: [
      '小程序全球编程创新挑战赛由北京师范大学、中国福利会少年宫联合微信，面向全球青少年发起，致力于通过高水平的赛事激发全球青少年丰富的创造力。自2022年9月起，在新课标要求“信息科技”独立设课并成为全国中小学生必修课的同时，青少年人工智能与未来媒体全球创新挑战赛拉开序幕。2023年11月，随着小程序教育平台在第六届中国教育创新成果公益博览会（教博会）上亮相，第二届微信创新实验室小程序编程全球挑战赛也官宣启动。',
    ],
  },
  {
    title: '主委目标',
    content: [
      '过去的两届比赛，共有来自北京、上海、广东、重庆、浙江、香港、澳门等地，全球五大赛区队伍提交了近1000份作品。在开放式命题下，参赛的“小小程序员”们用中国原创编程语言微信小程序等编程工具做出了极具创意的作品。',
    ],
  },
];

export type CompetitionStage = {
  type: 'start' | 'middle' | 'end';
  stageName: string;
  time: string;
};

export const COMPETITION_TIMELINE: CompetitionStage[] = [
  {
    type: 'start',
    stageName: '赛事启动',
    time: '2024 / 6',
  },
  {
    type: 'middle',
    stageName: '作品提交',
    time: '2024 / 7 - 2024 / 9',
  },
  {
    type: 'middle',
    stageName: '赛事培训',
    time: '2024 / 7 - 2024 / 9',
  },
  {
    type: 'middle',
    stageName: '作品初评',
    time: '2024 / 10',
  },
  {
    type: 'end',
    stageName: '作品终评',
    time: '2024 / 11',
  },
];

export type CompetitionMessage = {
  type: 'file' | 'link';
  url: string;
  title: string;
};

export const COMPETITION_MESSAGE: CompetitionMessage[] = [
  {
    type: 'file',
    url: 'https://7465-testchu-7gy8occc8dcc14c3-1304825656.tcb.qcloud.la/competition-file/%EF%BC%88%E4%BE%BF%E7%AC%BA%5B2024%5D228%E5%8F%B7%EF%BC%89%E5%85%B3%E4%BA%8E%E4%B8%BE%E5%8A%9E%E5%8F%8C%E5%9F%8E%E9%9D%92%E5%B0%91%E5%B9%B4%E7%BC%96%E7%A8%8B%E5%88%9B%E6%96%B0%E8%B5%9B%E7%9A%84%E9%80%9A%E7%9F%A5(1).pdf?sign=a9143ce9a77954ad65edc3ee55497309&t=1725440307',
    title: '（便笺[2024]228号）关于举办双城青少年编程创新赛的通知',
  },
];
