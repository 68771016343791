import { FormattedMessage } from 'react-intl';

const courseList = [
  {
    title: {
      id: 'getStart.title',
    },
    courseGroup: [
      {
        type: <FormattedMessage id="visualProgram.type" />,
        title: {
          id: 'visualProgram.title',
        },
        color: 'rgba(7,193,96,1)',
        border: '0.5px solid rgba(7,193,96,0.15)',
        backgroundImage:
          'linear-gradient(rgba(225,247,234,0.5), rgba(225,247,234,0))',
        list: [
          {
            url: 'https://developers.weixin.qq.com/community/business/course/00004e14560390bf0fe1e74fe6100d',
            text: <FormattedMessage id="visualProgram.list1" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/0002a2dbf08c50dface1a313466c0d',
            text: <FormattedMessage id="visualProgram.list2" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/000cc8508f4138b0b6e1963b066c0d',
            text: <FormattedMessage id="visualProgram.list3" />,
          },
        ],
      },
      {
        type: <FormattedMessage id="codeProgram.type" />,
        title: {
          id: 'codeProgram.title',
        },
        color: 'rgba(250,157,59,1)',
        border: '0.5px solid rgba(250,157,59,0.15)',
        backgroundImage:
          'linear-gradient(rgba(255,236,178,0.3), rgba(255,236,178,0))',
        list: [
          {
            url: 'https://developers.weixin.qq.com/community/business/course/000ca4b0990a9087193d27b905780d',
            text: <FormattedMessage id="codeProgram.list1" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/00082803ba0ad06ce59d416545bc0d',
            text: <FormattedMessage id="codeProgram.list2" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/0002ae96dc0f583a3db13250e6b00d',
            text: <FormattedMessage id="codeProgram.list3" />,
          },
        ],
      },
      {
        type: <FormattedMessage id="advancedDevelopment.type" />,
        title: {
          id: 'advancedDevelopment.title',
        },
        color: 'rgba(16,174,255,1)',
        border: '0.5px solid rgba(16,174,255,0.15)',
        backgroundImage:
          'linear-gradient(rgba(183,230,255,0.3), rgba(183,230,255,0))',
        list: [
          {
            url: 'https://developers.weixin.qq.com/community/business/course/000a6efdc1ce10c9a8b0ca0a16100d',
            text: <FormattedMessage id="advancedDevelopment.citywalk" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/00060808f9070850621049b9c6a40d',
            text: <FormattedMessage id="advancedDevelopment.praticalClass" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/000ae232aa8928df55108dffc66c0d',
            text: <FormattedMessage id="advancedDevelopment.carbonComputer" />,
          },
        ],
      },
    ],
  },
  {
    title: {
      id: 'miniprogram.type',
    },
    courseGroup: [
      {
        type: <FormattedMessage id="techClass.type" />,
        title: {
          id: 'techClass.title',
        },
        color: 'rgba(7,193,96,1)',
        border: '0.5px solid rgba(7,193,96,0.15)',
        backgroundImage:
          'linear-gradient(rgba(225,247,234,0.5), rgba(225,247,234,0))',
        list: [
          {
            url: 'https://developers.weixin.qq.com/community/business/course/0008880eaf82488b32b14d0546b00d',
            text: <FormattedMessage id="techClass.1to6" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/00004873a244583b3ab1f89e06b00d',
            text: <FormattedMessage id="techClass.7" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/000ee4667ecf28084cb1285d866c0d',
            text: <FormattedMessage id="techClass.8to9" />,
          },
        ],
      },
      {
        type: <FormattedMessage id="mathClass.type" />,
        title: {
          id: 'mathClass.title',
        },
        color: 'rgba(250,157,59,1)',
        border: '0.5px solid rgba(250,157,59,0.15)',
        backgroundImage:
          'linear-gradient(rgba(255,236,178,0.3), rgba(255,236,178,0))',
        list: [
          {
            url: 'https://developers.weixin.qq.com/community/business/course/0004aa549b8ba8bb50b0af4f866c0d',
            text: <FormattedMessage id="mathClass.elementary" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/000880e0a906e0dcaf61259fc6680d',
            text: <FormattedMessage id="mathClass.junior" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/000686727fc2e0778d41c3d7c6600d',
            text: <FormattedMessage id="mathClass.senior" />,
          },
        ],
      },
      {
        type: <FormattedMessage id="moreClass.type" />,
        title: {
          id: 'moreClass.title',
        },
        color: 'rgba(16,174,255,1)',
        border: '0.5px solid rgba(16,174,255,0.15)',
        backgroundImage:
          'linear-gradient(rgba(183,230,255,0.3), rgba(183,230,255,0))',
        list: [
          {
            url: 'https://developers.weixin.qq.com/community/business/course/000e46ac804150d836b1aa0e461c0d',
            text: <FormattedMessage id="moreClass.historyAndEnglish" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/00006cf4988cc0b83ab1daa3368c0d',
            text: <FormattedMessage id="moreClass.artAndScience" />,
          },
          {
            url: 'https://developers.weixin.qq.com/community/business/course/00002a10b48a48293eb188d536b40d',
            text: <FormattedMessage id="moreClass.vocationalAndTechnical" />,
          },
        ],
      },
    ],
  },
];

export default courseList;
