import { useEffect, useState } from 'react';
import { sendLog } from '../../config/report';
import {
  ApplicationType,
  getDownloadFileName,
  getOperatingSystem,
} from '../../config/utils';
import { FormattedMessage } from 'react-intl';
import { useDeviceStore, useDialogStore } from '../../store';
import { DIALOG_ID } from '../../typing/global';
interface IDownloadDialogProps {}

const DownloadDialog = (props: IDownloadDialogProps) => {
  const [downloadType, setDownloadtype] = useState<undefined | string>(
    undefined,
  );
  const { screenWidth } = useDeviceStore();
  const closeDialog = useDialogStore((state) => state.closeDialog);

  // 拉取失败默认走到0705的版本，是否需要更新默认版本
  const [version, setVersion] = useState('1.06.2407055');
  const [prefix, setPrefix] = useState(
    'https://dldir1.qq.com/WechatWebDev/nightly-edu/p-3bd19c2db3a642a0b39af853efaf67f8/0.54.1/',
  );
  const handleNavigation = (url: string) => {
    window.location.href = url;
  };
  useEffect(() => {
    const platform = getOperatingSystem();
    if (platform === 'Mac') {
      setDownloadtype('Mac');
    }
    if (platform === 'Windows') {
      setDownloadtype('Windows');
    }
    if (platform == 'Unknown') {
      //...
    }
    const jsonUrl =
      'https://dldir1.qq.com/WechatWebDev/nightly-edu/versioninfo/0.54.1/version.json'; // https://dldir1.qq.com/WechatWebDev/nightly-edu/versioninfo/0.54.1/version.json
    fetch(jsonUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // console.log('111',data)
        let VersionStr = data.update_version.toString();
        if (VersionStr.length === 10) {
          VersionStr = VersionStr.slice(0, 1) + '.' + VersionStr.slice(1);
          VersionStr = VersionStr.slice(0, 4) + '.' + VersionStr.slice(4);
          setVersion(VersionStr);
        }
        if (data.update_url_prefix) {
          setPrefix(data.update_url_prefix.replace('0.54.1-173', '0.54.1'));
        }
      })
      .catch((error) => {
        console.error(
          'There has been a problem with your fetch operation11:',
          error,
        );
      });

    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  return (
    <div className="download-dialog dialog-top">
      <div style={{ scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}` }}>
        <div
          style={{
            width: '640px',
            padding: '32px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
            alignItems: 'center',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
          }}
        >
          <div
            onClick={() => {
              closeDialog(DIALOG_ID.DOWNLOAD);
            }}
            style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9987 13.0605L5.55857 19.4994L4.49801 18.4386L10.9379 11.9999L4.49707 5.56024L5.55763 4.49948L11.9987 10.9394L18.4423 4.49695L19.5029 5.55771L13.0594 11.9999L19.5029 18.4423L18.4424 19.503L11.9987 13.0605Z"
                fill="black"
              />
            </svg>
          </div>
          <p
            style={{
              fontSize: '20px',
              fontWeight: '500',
              color: 'rgba(0,0,0,0.9)',
            }}
          >
            {downloadType == 'Mac' ? (
              <FormattedMessage id="downloadDialog.downloadEducationSuiteMacVersion" />
            ) : (
              <FormattedMessage id="downloadDialog.downloadEducationSuiteWindowsVersion" />
            )}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '400',
              color: 'rgba(0,0,0,0.3)',
              marginTop: '8px',
              marginBottom: '32px',
            }}
          >
            {version}
          </p>
          <a
            onClick={() => {
              if (downloadType === 'Mac') {
                sendLog({ method: '官网用户下载工具macOS x64' });
              } else {
                sendLog({ method: '官网用户下载工具Windows 64' });
              }
            }}
            style={{
              cursor: 'pointer',
              width: '320px',
              height: '60px',
              backgroundColor: 'rgba(0,195,131,1)',
              fontSize: '17px',
              fontWeight: '500',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}
            href={
              downloadType == 'Mac'
                ? `${prefix}${getDownloadFileName(
                    version,
                    ApplicationType.darwin64,
                  )}`
                : `${prefix}${getDownloadFileName(
                    version,
                    ApplicationType.win64,
                  )}`
            }
          >
            {downloadType == 'Mac' ? (
              <FormattedMessage id="downloadDialog.IntelChipVersion" />
            ) : (
              <FormattedMessage id="downloadDialog.windows64Version" />
            )}
          </a>
          <a
            onClick={() => {
              if (downloadType === 'Mac') {
                sendLog({ method: '官网用户下载工具macOS ARM64' });
              } else {
                sendLog({ method: '官网用户下载工具Windows 32' });
              }
            }}
            style={{
              cursor: 'pointer',
              marginTop: '24px',
              marginBottom: '16px',
              width: '320px',
              height: '60px',
              backgroundColor: 'rgba(0,0,0,0.03)',
              fontSize: '17px',
              fontWeight: '500',
              color: 'black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
            }}
            href={
              downloadType == 'Mac'
                ? `${prefix}${getDownloadFileName(
                    version,
                    ApplicationType.darwinArm,
                  )}`
                : `${prefix}${getDownloadFileName(
                    version,
                    ApplicationType.win32,
                  )}`
            }
          >
            {downloadType == 'Mac' ? (
              <FormattedMessage id="downloadDialog.appleChipVersion" />
            ) : (
              <FormattedMessage id="downloadDialog.windows32Version" />
            )}
          </a>
          <a
            className="version-btn"
            onClick={() =>
              handleNavigation('https://edu.weixin.qq.com/version')
            }
          >
            <FormattedMessage id="downloadDialog.updateLog" />
          </a>
        </div>
        <div
          style={{
            width: '640px',
            padding: '40px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: '#F7F7F7',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
          }}
        >
          <p
            style={{
              fontSize: '14px',
              fontWeight: '500',
              color: 'rgba(0,0,0,0.9)',
            }}
          >
            {downloadType == 'Mac' ? (
              <FormattedMessage id="downloadDialog.howToConfirmChipType" />
            ) : (
              <FormattedMessage id="downloadDialog.howToConfirmVersionType" />
            )}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '400',
              color: 'rgba(0,0,0,0.7)',
              marginTop: '16px',
              marginBottom: '8px',
            }}
          >
            {downloadType == 'Mac' ? (
              <FormattedMessage id="downloadDialog.aboutThisMac" />
            ) : (
              <FormattedMessage id="downloadDialog.systemInfo" />
            )}
          </p>
          <p
            style={{
              fontSize: '13px',
              fontWeight: '400',
              color: 'rgba(0,0,0,0.7)',
            }}
          >
            {downloadType == 'Mac' ? (
              <FormattedMessage id="downloadDialog.checkChipType" />
            ) : (
              <FormattedMessage id="downloadDialog.checkVersionType" />
            )}
          </p>
          {downloadType === 'Windows' && (
            <p
              style={{
                fontSize: '13px',
                fontWeight: '400',
                color: 'rgba(0,0,0,0.3)',
                marginTop: '24px',
              }}
            >
              <FormattedMessage id="downloadDialog.supportWindowsVersion" />
            </p>
          )}
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default DownloadDialog;
