import { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import courseList from '../../../config/courseList';

const OfficialTextBlockMobile = () => {
  const [hoveredItemId, setHoverdItemId] = useState<undefined | string>(
    undefined,
  );

  const intl = useIntl();

  return (
    <>
      {courseList.map((courseData, courseListIndex) => {
        const courseGroup = courseData.courseGroup || [];
        return (
          <div
            key={intl.formatMessage(courseData.title)}
            // className="courses"
            style={{ width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                height: '28px',
                margin: '32px 16px 12px 16px',
              }}
            >
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  backgroundColor: 'rgba(16,174,255,1)',
                  marginRight: '6px',
                  borderRadius: '1px',
                }}
              ></div>
              <div
                style={{
                  fontSize: '15px',
                  fontWeight: '500',
                  marginRight: '6px',
                  lineHeight: '28px',
                }}
              >
                {intl.formatMessage(courseData.title)}
              </div>
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  backgroundColor: 'rgba(7,193,96,1)',
                  alignSelf: 'end',
                  borderRadius: '1px',
                }}
              ></div>
            </div>

            {courseGroup.map((courseGroupData, groupIndex) => {
              const courseList = courseGroupData.list || [];
              return (
                <div
                  key={intl.formatMessage(courseData.title)}
                  style={{
                    margin: '0 16px 12px 16px',
                    backgroundColor: 'white',
                    boxSizing: 'border-box',
                    border: courseGroupData.border,
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    style={{
                      backgroundImage: courseGroupData.backgroundImage,
                      padding: '24px',
                    }}
                  >
                    <div
                      style={{
                        height: '20px',
                        fontSize: '14px',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        color:
                          groupIndex == 0
                            ? 'rgba(7,193,96,1)'
                            : groupIndex == 1
                              ? 'rgba(250,157,59,1)'
                              : 'rgba(16,174,255,1)',
                      }}
                    >
                      {courseGroupData.type}
                    </div>
                    <div
                      style={{
                        height: '21px',
                        marginTop: '8px',
                        fontSize: '15px',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        color: 'rgba(0,0,0,0.9)',
                      }}
                    >
                      {intl.formatMessage(courseGroupData.title)}
                    </div>
                  </div>
                  <div style={{ padding: '0px 12px 20px 12px' }}>
                    {courseList.map((courseData, courseIndex) => {
                      const courseId = `${courseListIndex}-${groupIndex}-${courseIndex}}`;
                      return (
                        <div
                          key={courseId}
                          onClick={() => {
                            window.open(courseData.url, '_blank');
                          }}
                          className="official-text-block__course-item"
                          style={{
                            cursor: 'pointer',
                            width: '100%',
                            borderRadius: '8px',
                            padding: '12px 12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '14px',
                              height: '20px',
                              color: 'rgba(0,0,0,0.9)',
                            }}
                          >
                            {courseData.text}
                          </div>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M12 20.8C16.8601 20.8 20.8 16.8601 20.8 12C20.8 7.13989 16.8601 3.2 12 3.2C7.13989 3.2 3.2 7.13989 3.2 12C3.2 16.8601 7.13989 20.8 12 20.8ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
                              fill="black"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.8216 11.418L10.265 7.32014C10.1855 7.27048 10.0937 7.24414 10 7.24414C9.72386 7.24414 9.5 7.468 9.5 7.74414V15.9399C9.5 16.0336 9.52633 16.1254 9.576 16.2049C9.72236 16.4391 10.0308 16.5102 10.265 16.3639L16.8216 12.266C16.886 12.2258 16.9404 12.1714 16.9806 12.107C17.127 11.8728 17.0558 11.5644 16.8216 11.418Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default OfficialTextBlockMobile;
