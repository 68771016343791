import React, { useState } from 'react';
import { HeadBarMobile } from '../../../components/HeadBarMobile';
import { MobilePopup } from '../../../components/MobilePopup';
import { CopyRightMobile } from '../../login/mobile/Login';
import { useNavigate } from 'react-router-dom';
import { UserStore, logout, useUserStore } from '../../../store';

interface IParticipationInfoMobileProps {
  info: any;
  renderResultItem: (k: string, v: string) => any;
  renderMemberList: () => any;
}

const ParticipationInfoMobile = (props: IParticipationInfoMobileProps) => {
  const userManager = useUserStore.getState() as UserStore;
  const [showLogoutMobilePopup, setShowLogoutMobilePopup] = useState(false);

  const { info, renderMemberList } = props;

  const renderResultItem = (k: string, v: string) => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          minHeight: '40px',
        }}
      >
        <div
          style={{
            flex: '0 0 124px',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.55)',
          }}
        >
          {k}
        </div>
        <div
          style={{
            color: 'rgba(0,0,0,0.9)',
            fontSize: '14px',
            fontWeight: '400',
            maxWidth: '236px',
          }}
        >
          {v}
        </div>
      </div>
    );
  };
  const navigate = useNavigate();

  return (
    <div
      className="CompetitionContainer"
      style={{
        width: '100%',
        backgroundColor: 'rgb(248,248,248)',
        position: 'relative',
        minHeight: '100vh',
      }}
    >
      {showLogoutMobilePopup && (
        <MobilePopup
          word={`退出账号 ${userManager.account} ?`}
          cancelWord="取消"
          confirmAction={() => {
            logout();
            navigate('/eventDetails');

            setShowLogoutMobilePopup(false);
          }}
          cancelAction={() => {
            setShowLogoutMobilePopup(false);
          }}
          confirmWord="退出"
        />
      )}
      <HeadBarMobile
        word="双城青少年编程创新赛"
        leftClick={() => {
          navigate('/eventDetails');
        }}
        rightAction={userManager.ticket !== '' ? userManager.account : '登录'}
        rightClick={() => {
          if (userManager.ticket !== '') {
            // 退出登录提示框
            setShowLogoutMobilePopup(true);
          } else {
            navigate('/loginMobile');
          }
        }}
      />
      <div style={{ height: '40px' }}></div>
      <div
        style={{
          transformOrigin: 'top',
          padding: '0 18px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%', marginBottom: '32px' }}>
          <p style={{ fontSize: '20px', color: 'black', fontWeight: '500' }}>
            查看报名信息
          </p>
        </div>

        <div
          style={{
            width: '100%',
            borderRadius: '12px',
            backgroundColor: 'white',
            border: '0.5px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '32px 16px',
          }}
        >
          {info.leader_name && (
            <>
              <div
                style={{
                  width: '100%',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                参赛成员信息
              </div>
              <div style={{ height: '24px' }}></div>
              {renderResultItem(
                '报名账号',
                userManager.account ? userManager.account : '',
              )}
              <div style={{ height: '24px' }}></div>
              {renderResultItem('姓名', info.leader_name)}

              <div style={{ height: '24px' }}></div>
              {renderResultItem('所属学校', info.leader_school_name)}

              <div style={{ height: '24px' }}></div>
              {renderResultItem('身份证号', info.leader_IDCard)}
              <div style={{ height: '24px' }}></div>
              {renderResultItem('手机', info.leader_mobile)}
              <div style={{ height: '24px' }}></div>

              {renderMemberList()}
              <div
                style={{
                  width: '100%',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                参赛团队信息
              </div>

              <div style={{ height: '24px' }}></div>
              {renderResultItem('分组', info.entry_group)}

              <div style={{ height: '24px' }}></div>

              {/* <div style={{ width: '100%', height: '40px', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '600' }}>指导老师信息</div>
                            <div style={{ height: '24px' }}></div> */}
              {renderResultItem('姓名', info.guide_teacher_name)}

              <div style={{ height: '24px' }}></div>
              {renderResultItem('联系方式', info.guide_teacher_mobile)}
            </>
          )}
        </div>

        <div style={{ height: '120px' }}></div>

        <div style={{ position: 'absolute', bottom: '40px', width: '100%' }}>
          <CopyRightMobile />
        </div>
      </div>
    </div>
  );
};

export default ParticipationInfoMobile;
