import { sendLog } from '../config/report';

interface IHeadBarProps {
  leftClick?: () => void;
  rightClick?: () => void;
  word: string;
  rightAction: string;
}

export function HeadBarMobile(props: IHeadBarProps) {
  const { leftClick, rightClick, word, rightAction } = props;
  return (
    <div
      style={{
        padding: '18px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        zIndex: '1',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          zIndex: '10',
          height: '24px',
          cursor: 'pointer',
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM15.0061 12.7242L15.1787 12.7198C16.7498 12.4466 17.8818 11.2075 17.8818 9.72727C17.8818 8.04739 16.3866 6.7 14.5515 6.7C12.7164 6.7 11.2212 8.04739 11.2212 9.72727V14.2727C11.2212 15.1038 10.4162 15.7909 9.4 15.7909C8.38376 15.7909 7.57879 15.1038 7.57879 14.2727C7.57879 13.6121 8.10106 13.0213 8.86433 12.8371C9.20564 12.7518 9.51898 12.5042 9.64544 12.2091C9.66569 12.1699 9.68311 12.1186 9.69213 12.0645C9.6986 12.0257 9.70016 11.9959 9.7 11.9388C9.7 11.5655 9.38777 11.3061 8.97576 11.3061H8.85454C7.25945 11.5453 6.1 12.7991 6.1 14.2727C6.1 15.9526 7.59521 17.3 9.4303 17.3C11.2654 17.3 12.7606 15.9526 12.7606 14.2727V9.72727C12.7606 8.89618 13.5656 8.20909 14.5818 8.20909C15.5981 8.20909 16.403 8.89618 16.403 9.72727C16.403 10.4133 15.8864 11.0076 15.1175 11.1932C14.7684 11.2707 14.4702 11.5089 14.3364 11.8212C14.1243 12.2982 14.4623 12.7242 15.0061 12.7242Z"
            fill="black"
            fill-opacity="0.9"
          />
        </svg>
        <p
          onClick={() => {
            if (leftClick) {
              leftClick();
            }
          }}
          style={{ fontSize: '16px', fontWeight: '500', marginLeft: '8px' }}
          className="font-weixin"
        >
          {word}
        </p>
      </div>

      <div style={{ zIndex: '10' }}>
        <span
          onClick={() => {
            sendLog({ method: '官网赛事页面点击首页', platform: 'mobile' });
            if (rightClick) {
              rightClick();
            }
          }}
          style={{ cursor: 'pointer', color: 'balck', fontSize: '14px' }}
        >
          {rightAction}
        </span>
      </div>
    </div>
  );
}
