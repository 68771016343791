import Commit from '../pages/commit/commit';
import Competition from '../pages/competition/Competition';
import CompetitionItem from '../pages/competitionItem';
import LoginMobile from '../pages/login/mobile/Login';
import Official from '../pages/official';
import ParticipationInfo from '../pages/participationInfo/ParticipationInfo';
import RegistMobile from '../pages/regist/mobile/Regist';
import RewardList from '../pages/rewardList/rewardList';
import SignUp from '../pages/signUp/SignUp';
import Version from '../pages/version';

const router = [
  {
    path: '/',
    element: <Official />,
  },
  {
    path: '/competition',
    element: <Competition />,
  },
  {
    path: '/eventDetails',
    element: <CompetitionItem />,
  },
  {
    path: '/rewardList',
    element: <RewardList />,
  },
  {
    path: '/signUp',
    element: <SignUp />,
  },
  {
    path: '/participationInfo',
    element: <ParticipationInfo />,
  },
  {
    path: '/loginMobile',
    element: <LoginMobile />,
  },
  {
    path: '/registMobile',
    element: <RegistMobile />,
  },
  {
    path: '/commit',
    element: <Commit />,
  },
  {
    path: '/version',
    element: <Version />,
  },
  {
    path: '*',
    element: <Official />,
  },
];

export default router;
