import { useEffect, useState } from 'react';
import {
  UserStore,
  logout,
  useDeviceStore,
  useUserStore,
} from '../../../store';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import urlConfigs from '../../../config/urlList';
import { sendLog } from '../../../config/report';
import { CopyRightMobile } from '../../login/mobile/Login';
import { HeadBarMobile } from '../../../components/HeadBarMobile';
import { MobilePopup } from '../../../components/MobilePopup';
import { ParticipationStatus } from '../../../typing/global';
import './CompetitionItem.css';
import {
  COMPETITION_DETAIL,
  COMPETITION_MESSAGE,
  CompetitionMessage,
} from '../../../config/competition';
import { EduSvgCollection } from '../../../components/SvgManager';

export const CompetitionTitle = (props: { title: string; style?: Object }) => {
  const { title, style } = props;
  return (
    <div
      key={title}
      style={Object.assign({ width: '100%' }, style)}
    >
      <div className="competition-item-mobile__title">
        <div className="competition-item-mobile__title__blue-point"></div>
        <span>{title}</span>
        <div className="competition-item-mobile__title__green-point"></div>
      </div>
    </div>
  );
};

const CompetitionMessageItem = (message: CompetitionMessage) => {
  const { type, url, title } = message;
  const handleNavigation = () => {
    window.open(url);
  };
  return (
    <div
      className="competition-item-mobile__message-item"
      onClick={handleNavigation}
    >
      {/* todo: icon */}
      {title}
    </div>
  );
};

const DetailParagraph = (props: { title: string; content: string[] }) => {
  const { title, content } = props;
  return (
    <>
      <div className="competition-item-mobile__detail__title">
        <div className="competition-item-mobile__detail__title-bar"></div>
        {title}
      </div>
      {content.map((text, index) => {
        return <p key={title + index}>{text}</p>;
      })}
    </>
  );
};

const CompetitionItem = () => {
  const { screenWidth } = useDeviceStore((state) => state);

  const [showLogoutMobilePopup, setShowLogoutMobilePopup] = useState(false);
  const [parted, setParted] = useState(false);
  const navigate = useNavigate();
  const preURL = process.env.PUBLIC_URL;
  const userManager = useUserStore((state) => state) as UserStore;

  const getPerticipationInfo = async () => {
    const data = {
      competition_uuid: '20240620',
    };
    const url = `${urlConfigs.edudev_getparticipationinfo}?eduticket=${userManager.ticket}`;

    try {
      const resp = await axios.post(url, JSON.stringify(data));

      if (resp.data.errcode === 0 && resp.data.participation_info) {
        if (
          resp.data.participation_info.status === ParticipationStatus.parted
        ) {
          setParted(true);
        }
        if (
          resp.data.participation_info.status === ParticipationStatus.submitted
        ) {
          setParted(true);
        }
      } else {
        setParted(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const goForMoreDetail = () => {
    window.open(
      'https://doc.weixin.qq.com/pdf/d3_AZUAUgb5AMIR9rSC5TmRaSgsYYcOh?scode=AJEAIQdfAAo6xCXfAeAZUAUgb5AMI',
    );
  };

  useEffect(() => {
    getPerticipationInfo();
  }, [userManager.ticket]);

  useEffect(() => {
    sendLog({ method: '官网赛事页面曝光', platform: 'mobile' });
  }, []);

  return (
    <div className="competition-item-mobile">
      {showLogoutMobilePopup && (
        <MobilePopup
          word={`退出账号 ${userManager.account} ?`}
          cancelWord="取消"
          confirmAction={() => {
            logout();
            navigate('/eventDetails');

            setShowLogoutMobilePopup(false);
          }}
          cancelAction={() => {
            setShowLogoutMobilePopup(false);
          }}
          confirmWord="退出"
        />
      )}

      <HeadBarMobile
        word="双城青少年编程创新赛"
        leftClick={() => {
          navigate('/eventDetails');
        }}
        rightAction={userManager.ticket !== '' ? userManager.account : '登录'}
        rightClick={() => {
          if (userManager.ticket !== '') {
            // 退出登录提示框
            setShowLogoutMobilePopup(true);
          } else {
            navigate('/loginMobile');
          }
        }}
      />

      <img
        className="competition-item-mobile__img"
        src={`${preURL}/img/mobile/compDefalutBanner.png`}
        alt=""
      />
      {/* <img src={`${preURL}/img/mobile/compBanner1.jpg`} style={{ width: '100%', height: 'auto', position: 'absolute', top: '0', zIndex: '0' }} alt="" /> */}

      <div
        style={{
          marginTop: `${(screenWidth * 390) / 500 - 50}px`,
          zIndex: '10',
        }}
      >
        <p style={{ fontSize: '24px', fontWeight: '700', textAlign: 'center' }}>
          双城青少年编程创新赛
        </p>
        <p style={{ fontSize: '20px', fontWeight: '700', textAlign: 'center' }}>
          暨微信小程序全球编程创新挑战赛川渝赛区选拔赛
        </p>
      </div>

      <div
        style={{
          marginTop: '24px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!parted ? (
          <div
            onClick={() => {
              sendLog({
                method: '官网赛事页面点击立即报名',
                platform: 'mobile',
              });

              if (userManager.ticket !== '') {
                navigate('/signUp');
              } else {
                navigate('/loginMobile');
              }
            }}
            style={{
              backgroundColor: 'rgba(7, 193, 96, 1)',
              color: 'white',
              width: '167px',
              height: '46px',
              borderRadius: '8px',
              fontSize: '17px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: '500',
            }}
          >
            立即报名
          </div>
        ) : (
          <div
            onClick={() => {
              sendLog({
                method: '官网赛事页面点击查看报名信息',
                platform: 'mobile',
              });
              navigate('/participationInfo');
            }}
            style={{
              backgroundColor: 'rgba(0,0,0,0.03)',
              color: 'rgba(0,0,0,0.9)',
              width: '167px',
              height: '46px',
              borderRadius: '8px',
              fontSize: '17px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: '500',
            }}
          >
            我的参赛信息
          </div>
        )}
      </div>

      {/* 关键时间 */}
      <div style={{ marginTop: '40px' }}>
        <CompetitionTitle title="关键时间" />

        <div
          style={{
            height: `${
              ((((screenWidth - 36) * 224) / 357) * screenWidth) / 390
            }px`,
            backgroundColor: 'white',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '32px',
            marginTop: '8px',
            border: '0.5px solid rgba(0, 0, 0, 0.1)',
          }}
        >
          <div
            style={{
              scale: `${screenWidth < 390 ? screenWidth / 390 : 1}`,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              className="noWrapText"
              style={{
                width: '100%',
                flex: '1 1 20%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '9px',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '9px',
                    height: '9px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    boxSizing: 'content-box',
                    border: '0px solid white',
                    zIndex: '2',
                  }}
                ></div>
                <div
                  style={{
                    width: '1px',
                    height: '50%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    position: 'absolute',
                    top: '50%',
                  }}
                ></div>
              </div>
              <div
                style={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.55)',
                  fontWeight: '500',
                  marginLeft: '16px',
                  marginRight: '16px',
                }}
              >
                2024/6
              </div>
              <div
                style={{
                  fontSize: '15px',
                  color: 'black',
                  fontWeight: '500',
                }}
              >
                赛事启动
              </div>
            </div>
            <div
              className="noWrapText"
              style={{
                width: '100%',
                flex: '1 1 20%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '9px',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '9px',
                    height: '9px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    boxSizing: 'content-box',
                    border: '0px solid white',
                    zIndex: '2',
                  }}
                ></div>
                <div
                  style={{
                    width: '1px',
                    height: '100%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    position: 'absolute',
                  }}
                ></div>
              </div>
              <div
                style={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.55)',
                  fontWeight: '500',
                  marginLeft: '16px',
                  marginRight: '16px',
                }}
              >
                2024/7-2024/9
              </div>
              <div
                style={{
                  fontSize: '15px',
                  color: 'black',
                  fontWeight: '500',
                }}
              >
                赛事作品提交
              </div>
            </div>
            <div
              className="noWrapText"
              style={{
                width: '100%',
                flex: '1 1 20%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '9px',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '9px',
                    height: '9px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    boxSizing: 'content-box',
                    border: '0px solid white',
                    zIndex: '2',
                  }}
                ></div>
                <div
                  style={{
                    width: '1px',
                    height: '100%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    position: 'absolute',
                  }}
                ></div>
              </div>
              <div
                style={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.55)',
                  fontWeight: '500',
                  marginLeft: '16px',
                  marginRight: '16px',
                }}
              >
                2024/7-2024/9
              </div>
              <div
                style={{
                  fontSize: '15px',
                  color: 'black',
                  fontWeight: '500',
                }}
              >
                赛事培训
              </div>
            </div>
            <div
              className="noWrapText"
              style={{
                width: '100%',
                flex: '1 1 20%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '9px',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '9px',
                    height: '9px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    boxSizing: 'content-box',
                    border: '0px solid white',
                    zIndex: '2',
                  }}
                ></div>
                <div
                  style={{
                    width: '1px',
                    height: '100%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    position: 'absolute',
                  }}
                ></div>
              </div>
              <div
                style={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.55)',
                  fontWeight: '500',
                  marginLeft: '16px',
                  marginRight: '16px',
                }}
              >
                2024/10
              </div>
              <div
                style={{
                  fontSize: '15px',
                  color: 'black',
                  fontWeight: '500',
                }}
              >
                作品初评
              </div>
            </div>
            <div
              className="noWrapText"
              style={{
                width: '100%',
                flex: '1 1 20%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '9px',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    width: '9px',
                    height: '9px',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    boxSizing: 'content-box',
                    border: '0px solid white',
                    zIndex: '2',
                  }}
                ></div>
                <div
                  style={{
                    width: '1px',
                    height: '50%',
                    backgroundColor: 'rgba(7,193,96,1)',
                    position: 'absolute',
                    bottom: '50%',
                  }}
                ></div>
              </div>
              <div
                style={{
                  fontSize: '14px',
                  color: 'rgba(0, 0, 0, 0.55)',
                  fontWeight: '500',
                  marginLeft: '16px',
                  marginRight: '16px',
                }}
              >
                2024/11
              </div>
              <div
                style={{
                  fontSize: '15px',
                  color: 'black',
                  fontWeight: '500',
                }}
              >
                作品终评
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: '40px' }}>
        <CompetitionTitle title="赛事详情" />

        <div className="competition-item-mobile__gray-wrap competition-item-mobile__detail">
          {COMPETITION_DETAIL.map((pharagraph) => {
            return (
              <DetailParagraph
                key={pharagraph.title}
                title={pharagraph.title}
                content={pharagraph.content}
              />
            );
          })}
          <div
            className="competition-item-mobile__more-detail"
            onClick={goForMoreDetail}
          >
            更多赛事详情
            <EduSvgCollection.ArrowRight />
          </div>
        </div>
      </div>
      <CompetitionTitle
        title="比赛动态"
        style={{ marginBottom: '16px', marginTop: '40px' }}
      />
      <div className="competition-item-mobile__gray-wrap competition-item-mobile__message">
        {COMPETITION_MESSAGE.map((message) => {
          return (
            <CompetitionMessageItem
              key={message.title}
              type={message.type}
              url={message.url}
              title={message.title}
            />
          );
        })}
      </div>

      <div style={{ marginTop: '48px' }}>
        <CopyRightMobile />
      </div>

      <div style={{ height: '100px' }}></div>
    </div>
  );
};

export default CompetitionItem;
