import { useEffect, useState } from 'react';
import {
  UserStore,
  logout,
  useDeviceStore,
  useDialogStore,
  useUserStore,
} from '../../store';
import { useNavigate } from 'react-router-dom';
import { sendLog } from '../../config/report';
import { LoginDialog } from '../../components/Dialog/LoginDialog';
import { FormattedMessage } from 'react-intl';
import { EduSvgCollection } from '../SvgManager';
import './HeadBarPC.css';
import { DIALOG_ID } from '../../typing/global';

const preURL = process.env.PUBLIC_URL;

export const HeadBarPC = () => {
  const [titleFixed, setTitleFixed] = useState(false);

  const { screenWidth } = useDeviceStore();
  const [showLogout, setShowLogout] = useState(false);
  const [showCompList, setShowCompList] = useState(false);

  const userManager = useUserStore((state) => state) as UserStore;
  const [showLoginDialog, setShowLoginDialog] = useState(false);

  const dialogStore = useDialogStore((state) => state);

  useEffect(() => {
    console.log('ticket refresh');
  }, [userManager.ticket]);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setTitleFixed(true);
    } else {
      setTitleFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const goToDoc = () => {
    window.open(
      'https://doc.weixin.qq.com/doc/w3_AZUAUgb5AMIKq13PZOKT3WSE5x64o?scode=AJEAIQdfAAoOyWCFADAZUAUgb5AMI',
    );
  };

  return (
    <>
      <div
        style={{
          width: '100vw',
          zIndex: '9',
          backgroundColor: `${titleFixed ? 'rgba(256,256,256,0.65)' : ''}`,
          backdropFilter: titleFixed ? 'blur(50px)' : '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: `${screenWidth > 1920 ? 83 : (83 * screenWidth) / 1920}px`,
          position: `${titleFixed ? 'fixed' : 'absolute'}`,
          marginTop: `${titleFixed ? '0px' : '20px'}`,
        }}
      >
        <div
          style={{
            width: `${
              screenWidth > 1920 ? 1224 : (1224 * screenWidth) / 1920
            }px`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}`,
              transformOrigin: 'left',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <EduSvgCollection.Logo />
            <p
              onClick={() => {
                navigate('/');
              }}
              style={{ fontSize: '28px', marginLeft: '8px' }}
              className="font-weixin"
            >
              <FormattedMessage id="HeadBarPC.platformName" />
            </p>
          </div>
          <div
            className="head-bar__navigation"
            style={{
              scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}`,
            }}
          >
            <nav onClick={goToDoc}>
              <FormattedMessage id="HeadBarPC.doc" />
            </nav>
            <nav
              onMouseEnter={() => {
                setShowCompList(true);
              }}
              onMouseLeave={() => {
                setShowCompList(false);
              }}
            >
              <p>
                <FormattedMessage id="HeadBarPC.competition" />
              </p>
              {showCompList && (
                <div
                  style={{
                    position: 'absolute',
                    padding: '12px 0px',
                    backgroundColor: ' white',
                    borderRadius: '8px',
                    border: '0.5px solid rgba(0,0,0,0.1)',
                    top: '40px',
                    left: '60px',
                  }}
                >
                  <div
                    className="header-bar-pc-comp-item"
                    onClick={() => {
                      navigate('/eventDetails');
                    }}
                  >
                    <span style={{ fontSize: '14px', marginRight: '8px' }}>
                      双城青少年编程创新赛
                    </span>
                    <span
                      style={{ fontSize: '12px', color: 'rgba(7,193,96,1)' }}
                    >
                      <FormattedMessage id="HeadBarPC.doing" />
                    </span>
                  </div>
                  <div
                    className="header-bar-pc-comp-item"
                    onClick={() => {
                      navigate('/competition');
                    }}
                  >
                    <span style={{ fontSize: '14px', marginRight: '8px' }}>
                      小程序全球编程创新挑战赛
                    </span>
                    <span
                      style={{ fontSize: '12px', color: 'rgba(0,0,0,0.3)' }}
                    >
                      <FormattedMessage id="HeadBarPC.end" />
                    </span>
                  </div>
                </div>
              )}
            </nav>
            {userManager.ticket == '' ? (
              <div
                onClick={() => {
                  dialogStore.openDialog(DIALOG_ID.LOGIN);
                }}
                style={{
                  width: '82px',
                  height: '40px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0,0,0,0.03)',
                  marginLeft: '8px',
                  cursor: 'pointer',
                }}
              >
                <p style={{ color: 'rgba(0,0,0,0.9)' }}>
                  <FormattedMessage id="HeadBarPC.login" />
                </p>
              </div>
            ) : (
              <div
                onMouseEnter={() => {
                  setShowLogout(true);
                }}
                onMouseLeave={() => {
                  setShowLogout(false);
                }}
                style={{ position: 'relative' }}
              >
                <div
                  style={{
                    padding: '0 12px',
                    height: '40px',
                    borderRadius: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.03)',
                    marginLeft: '8px',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={`${preURL}/svg/avatar.svg`}
                    alt=""
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                  <p style={{ color: 'rgba(0,0,0,0.55)' }}>
                    {userManager.account}
                  </p>
                </div>
                {showLogout && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      flexDirection: 'column',
                    }}
                    onClick={() => {
                      logout();
                      if (
                        window.location.href.includes('signUp') ||
                        window.location.href.includes('participationInfo') ||
                        window.location.href.includes('commit')
                      ) {
                        navigate('/eventDetails');
                      }
                      setShowLogout(false);
                    }}
                  >
                    <div style={{ width: '100px', height: '6px' }}></div>

                    <div
                      style={{
                        height: '52px',
                        width: '80px',
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        fontSize: '14px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '0.5px solid rgba(0,0,0,0.1)',
                      }}
                    >
                      <span>
                        <FormattedMessage id="HeadBarPC.logout" />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
