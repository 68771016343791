export type ScreenType = 'mobile' | 'desktop';

export const DIALOG_ID = {
  DOWNLOAD: Symbol('download'),
  REGIST: Symbol('regist'),
  LOGIN: Symbol('login'),
};

export enum ParticipationStatus {
  init = 0,
  parted = 1,
  submitted = 2,
}
