import React, { ChangeEvent, useEffect, useState } from 'react';
import { UserStore, useDeviceStore, useUserStore } from '../../store';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CheckBox,
  RadioInputBox,
  Input,
} from '../../components/Dialog/RegistDialog';
import axios from 'axios';
import urlConfigs from '../../config/urlList';
import { sendLog } from '../../config/report';
import SignUpMobile from './mobile/SignUp';
import { CopyRightPC } from '../login/mobile/Login';
import CommonPopup from '../../components/commonPopup';
import { HeadBarPC } from '../../components/HeadBar/HeadBarPC';
/* eslint-disable @typescript-eslint/no-unused-vars, no-empty, no-unsafe-optional-chaining */

export interface IMember {
  name: string;
  IDCard: string;
  mobile: string;
  school_name: string;
}

export interface ISignUpForm {
  account: string;
  name: string;
  school_name: string;
  id_number: string;
  phone_number: string;
  verifi_code: string;
  group: string;
  teacher_name: string;
  teacher_phone_number: string;
}

export const groupOptions = [
  '创意方向（1-6年级）',
  '创造方向-潜龙组（1-7年级）',
  '创造方向-飞龙组（8-12年级）',
];

const regexIDCard =
  /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/;
const regexPhone = /^1[3-9]\d{9}$/;

const SignUp = () => {
  const { screenWidth, setScreenWidth } = useDeviceStore();
  const [titleFixed, setTitleFixed] = useState(false);
  const [showTitleButton, setShowTitleButton] = useState(false);
  const [step, setStep] = useState(1);
  const userManager = useUserStore.getState() as UserStore;
  const [showLogout, setShowLogout] = useState(false);

  const [showCheckPcPopup, setShowCheckPcPopup] = useState(false);

  const [memberList, setMemberList] = useState<IMember[]>([]);
  const [memberListErrMsg, setMemberListErrMsg] = useState<IMember[]>([]);

  const [signUpForm, setSignUpForm] = useState<ISignUpForm>({
    account: userManager.account ? userManager.account : '',
    name: '',
    school_name: '',
    id_number: '',
    phone_number: '',
    verifi_code: '',
    group: '',
    teacher_name: '',
    teacher_phone_number: '',
  });

  const [signUpFormErrMsg, setSignUpFormErrMsg] = useState<ISignUpForm>({
    account: '',
    name: '',
    school_name: '',
    id_number: '',
    phone_number: '',
    verifi_code: '',
    group: '',
    teacher_name: '',
    teacher_phone_number: '',
  });

  const deleteMember = (index: number) => {
    const arr = [...memberList];
    arr.splice(index, 1);
    setMemberList(arr);

    // 同时del err list
    const arr2 = [...memberListErrMsg];
    arr2.splice(index, 1);
    setMemberListErrMsg(arr2);
  };

  const addMember = () => {
    setMemberList((memberList) => {
      if (memberList.length < 2) {
        return [
          ...memberList,
          {
            name: '',
            IDCard: '',
            mobile: '',
            school_name: '',
          },
        ];
      } else {
        return memberList;
      }
    });

    // 同时add err list
    setMemberListErrMsg((memberList) => {
      if (memberList.length < 2) {
        return [
          ...memberList,
          {
            name: '',
            IDCard: '',
            mobile: '',
            school_name: '',
          },
        ];
      } else {
        return memberList;
      }
    });
  };
  const navigate = useNavigate();
  const preURL = process.env.PUBLIC_URL;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    document.body.style.backgroundColor = 'rgba(248, 248, 248, 1)';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const renderResultItem = (k: string, v: string) => {
    return (
      <div
        style={{
          width: '340px',
          display: 'flex',
          alignItems: 'center',
          minHeight: '40px',
        }}
      >
        <div
          style={{
            flex: '0 0 124px',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.55)',
          }}
        >
          {k}
        </div>
        <div
          style={{
            color: 'rgba(0,0,0,0.9)',
            fontSize: '14px',
            fontWeight: '400',
            maxWidth: '236px',
          }}
        >
          {v}
        </div>
      </div>
    );
  };

  const handleInputChange = (item: string, val: any) => {
    if (signUpForm?.[item as keyof ISignUpForm] === undefined) return;
    setSignUpForm((prev) => ({
      ...prev,
      [item]: val,
    }));

    const newSignUpFormErrMsg = JSON.parse(JSON.stringify(signUpFormErrMsg));
    if (item === 'id_number') {
      if (!regexIDCard.test(val)) {
        newSignUpFormErrMsg[item] = '请输入正确的身份证号';
      } else {
        newSignUpFormErrMsg[item] = '';
      }
    }
    if (item === 'phone_number') {
      if (!regexPhone.test(val)) {
        newSignUpFormErrMsg[item] = '请输入正确的手机号';
      } else {
        newSignUpFormErrMsg[item] = '';
      }
    }
    if (item === 'teacher_phone_number') {
      if (!regexPhone.test(val)) {
        newSignUpFormErrMsg[item] = '请输入正确的手机号';
      } else {
        newSignUpFormErrMsg[item] = '';
      }
    }

    setSignUpFormErrMsg(newSignUpFormErrMsg);
  };

  const handleMemberChange = (index: number, item: string, val: string) => {
    if (memberList[index]?.[item as keyof IMember] === undefined) return;

    const list = [...memberList];
    // @ts-ignore
    list[index][item] = val;

    setMemberList(list);

    const newMemberListErrMsg = JSON.parse(JSON.stringify(memberListErrMsg));

    if (item === 'IDCard') {
      if (!regexIDCard.test(val)) {
        newMemberListErrMsg[index][item] = '请输入正确的身份证号';
      } else {
        newMemberListErrMsg[index][item] = '';
      }
    }
    if (item === 'mobile') {
      if (!regexPhone.test(val)) {
        newMemberListErrMsg[index][item] = '请输入正确的手机号';
      } else {
        newMemberListErrMsg[index][item] = '';
      }
    }
    setMemberListErrMsg(newMemberListErrMsg);
  };

  const renderMemberList = () => {
    const list = memberList.map((item, index) => {
      return (
        <>
          <div
            style={{
              width: '604px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              团队成员{index + 1}
            </div>
            <p
              onClick={() => {
                deleteMember(index);
              }}
              style={{
                fontSize: '14px',
                color: 'rgba(87, 107, 149, 1)',
                fontWeight: '500',
                cursor: 'pointer',
              }}
            >
              删除
            </p>
          </div>

          <div style={{ height: '24px' }}></div>
          <div style={{ width: '604px' }}>
            <Input
              errMsg={memberListErrMsg[index].name}
              desc="姓名"
              descWidth="124"
              placeHolder="请输入姓名"
              value={memberList[index].name}
              onChange={(e) =>
                handleMemberChange(index, 'name', e.target.value)
              }
            />
          </div>
          <div style={{ height: '24px' }}></div>
          <div style={{ width: '604px' }}>
            <Input
              errMsg={memberListErrMsg[index].school_name}
              desc="所属学校"
              descWidth="124"
              placeHolder="请输入所属学校"
              value={memberList[index].school_name}
              onChange={(e) =>
                handleMemberChange(index, 'school_name', e.target.value)
              }
            />
          </div>
          <div style={{ height: '24px' }}></div>
          <div style={{ width: '604px' }}>
            <Input
              errMsg={memberListErrMsg[index].IDCard}
              desc="身份证号"
              descWidth="124"
              placeHolder="请输入身份证号"
              value={memberList[index].IDCard}
              onChange={(e) =>
                handleMemberChange(index, 'IDCard', e.target.value)
              }
            />
          </div>
          <div style={{ height: '24px' }}></div>
          <div style={{ width: '604px' }}>
            <Input
              errMsg={memberListErrMsg[index].mobile}
              desc="手机"
              descWidth="124"
              placeHolder="请输入手机号码"
              value={memberList[index].mobile}
              onChange={(e) =>
                handleMemberChange(index, 'mobile', e.target.value)
              }
            />
          </div>
          <div style={{ height: '24px' }}></div>
        </>
      );
    });

    return list;
  };
  const renderMemberList2 = () => {
    const list = memberList.map((item, index) => {
      return (
        <>
          <div
            style={{
              width: '340px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            参赛成员{index + 1}
          </div>
          <div style={{ height: '24px' }}></div>

          {renderResultItem('姓名', item.name)}

          <div style={{ height: '24px' }}></div>
          {renderResultItem('所属学校', item.school_name)}

          <div style={{ height: '24px' }}></div>
          {renderResultItem('身份证号', item.IDCard)}
          <div style={{ height: '24px' }}></div>
          {renderResultItem('手机', item.mobile)}
          <div style={{ height: '24px' }}></div>
        </>
      );
    });

    return list;
  };

  // useEffect(() => {
  //     // 当signUpForm有值且signUpFormErrMsg没值且memberListErrMsg没值，跳转
  //     if (signUpForm.name === '' || signUpForm.group === '' || signUpForm.verifi_code === '') {
  //         return
  //     }

  //     const signUpFormErrMsgEmpty = Object.values(signUpFormErrMsg).every(value => value === '');
  //     let memberListErrMsgEmpty = true
  //     memberListErrMsg.forEach(item => {
  //         if (!Object.values(item).every(value => value === '')) {
  //             memberListErrMsgEmpty = false
  //         }
  //     })

  //     if (signUpFormErrMsgEmpty && memberListErrMsgEmpty) {
  //         setStep(2)
  //     } else {
  //         setStep(1)
  //     }

  // }, [signUpFormErrMsg])

  const nextStep = async () => {
    // 检查表单
    // setSignUpFormErrMsg({
    //     account: '',
    //     name: '',
    //     school_name: '',
    //     id_number: '',
    //     phone_number: '',
    //     verifi_code: '',
    //     group: '',
    //     teacher_name: '',
    //     teacher_phone_number: '',
    // })

    // setMemberListErrMsg((val) => {
    //     const newArr = []
    //     for (let i = 0; i < val.length; i++) {
    //         newArr.push({
    //             name: '',
    //             IDCard: '',
    //             mobile: '',
    //             school_name: '',
    //         })
    //     }
    //     return newArr
    // })

    // 检查表单
    const obj = {
      account: '',
      name: '',
      school_name: '',
      id_number: '',
      phone_number: '',
      verifi_code: '',
      group: '',
      teacher_name: '',
      teacher_phone_number: '',
    };
    const newArr = [];
    const val = memberList.length;
    for (let i = 0; i < val; i++) {
      newArr.push({
        name: '',
        IDCard: '',
        mobile: '',
        school_name: '',
      });
    }
    const arr = newArr;

    if (signUpForm.account == '') {
      obj.account = '账号不能为空';
    }
    if (signUpForm.name == '') {
      obj.name = '姓名不能为空';
    }
    if (signUpForm.school_name == '') {
      obj.school_name = '学校不能为空';
    }
    if (!regexIDCard.test(signUpForm.id_number)) {
      obj.id_number = '请检查身份证格式';
    }
    if (signUpForm.id_number == '') {
      obj.id_number = '身份证号不能为空';
    }
    if (!regexPhone.test(signUpForm.phone_number)) {
      obj.phone_number = '请检查手机号格式';
    }
    if (signUpForm.phone_number == '') {
      obj.phone_number = '手机号不能为空';
    }
    if (signUpForm.verifi_code == '') {
      obj.verifi_code = '验证码不能为空';
    }
    if (signUpForm.group == '') {
      obj.group = '分组不能为空';
    }
    if (signUpForm.teacher_name == '') {
      obj.teacher_name = '指导老师姓名不能为空';
    }
    if (!regexPhone.test(signUpForm.teacher_phone_number)) {
      obj.teacher_phone_number = '请检查老师手机号格式';
    }
    if (signUpForm.teacher_phone_number == '') {
      obj.teacher_phone_number = '老师手机号不能为空';
    }

    // 检查表单

    memberList.forEach((item, index) => {
      // console.log(item)
      if (!regexIDCard.test(item.IDCard)) {
        arr[index].IDCard = '请检查身份证格式';
      }
      if (!regexPhone.test(item.mobile)) {
        arr[index].mobile = '请检查手机号格式';
      }
      if (item.IDCard == '') {
        arr[index].IDCard = '身份证号不能为空';
      }
      if (item.mobile == '') {
        arr[index].mobile = '手机号不能为空';
      }
      if (item.name == '') {
        arr[index].name = '姓名不能为空';
      }
      if (item.school_name == '') {
        arr[index].school_name = '学校名不能为空';
      }
    });

    if (signUpForm.verifi_code !== '') {
      // 检查验证码
      const resp = await axios.post(
        urlConfigs.edudev_checksmsverifycode,
        JSON.stringify({
          verify_code: signUpForm.verifi_code,
          phone_number: signUpForm.phone_number,
        }),
      );

      // 根据resp，抛错，return
      if (resp.data.errcode !== 0) {
        obj.verifi_code = '验证码错误';
      }

      if (resp.data.errcode == 100143) {
        obj.verifi_code = '验证码检查次数受限，请稍后再试';
      }
    }

    setMemberListErrMsg(arr);
    setSignUpFormErrMsg(obj);

    const signUpFormErrMsgEmpty = Object.values(obj).every(
      (value) => value === '',
    );
    let memberListErrMsgEmpty = true;
    arr.forEach((item) => {
      if (!Object.values(item).every((value) => value === '')) {
        memberListErrMsgEmpty = false;
      }
    });

    if (signUpFormErrMsgEmpty && memberListErrMsgEmpty) {
      setStep(2);
    }
  };

  const modeifyForm = () => {
    setStep(1);
  };

  const confirmForm = async () => {
    const url = `${urlConfigs.edudev_signupcompetition}?eduticket=${userManager.ticket}`;
    // 提交
    const data = {
      verify_code: signUpForm.verifi_code,
      signup_info: {
        competition_uuid: '20240620',
        leader_school_name: signUpForm.school_name,
        leader_name: signUpForm.name,
        leader_mobile: signUpForm.phone_number,
        leader_IDCard: signUpForm.id_number,
        guide_teacher_name: signUpForm.teacher_name,
        guide_teacher_mobile: signUpForm.teacher_phone_number,
        entry_group: signUpForm.group,
      },
      member_info: [...memberList],
    };
    const resp = await axios.post(url, JSON.stringify(data));
    if (resp.data.errcode === 0) {
      setStep(3);
      if (screenWidth > 780) {
        sendLog({
          method: '官网赛事页面报名成功',
          user_id: signUpForm.account,
          platform: 'PC',
        });
      } else {
        sendLog({
          method: '官网赛事页面报名成功',
          user_id: signUpForm.account,
          platform: 'mobile',
        });
      }
    } else {
      setStep(4);
    }
    // 成功，跳成功step
    // 失败，跳失败step
    // console.log('报名resp', signUpForm, memberList, resp)
  };

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (seconds === 0) return;

    const intervalId = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);

  const sendsmsverifycode = async () => {
    const obj = {
      account: '',
      name: '',
      school_name: '',
      id_number: '',
      phone_number: '',
      verifi_code: '',
      group: '',
      teacher_name: '',
      teacher_phone_number: '',
    };
    const data = {
      phone_number: signUpForm.phone_number,
    };
    if (!regexPhone.test(data.phone_number)) {
      obj.phone_number = '请检查手机号格式';
      setSignUpFormErrMsg(obj);
      // setSignUpFormErrMsg({ ...signUpFormErrMsg, phone_number: '请检查手机号格式' })
      return;
    }
    const resp = await axios.post(
      urlConfigs.edudev_checksmsverifycode,
      JSON.stringify({
        verify_code: '',
        phone_number: data.phone_number,
      }),
    );
    // 根据resp，抛错，return
    if (resp.data.errcode == 100143) {
      obj.verifi_code = '验证码发送次数受限，请稍后再试';
      setSignUpFormErrMsg(obj);
      // setSignUpFormErrMsg({ ...signUpFormErrMsg, verifi_code: '验证码发送次数受限，请稍后再试' })
      return;
    }

    setSignUpFormErrMsg(obj);

    await axios.post(urlConfigs.edudev_sendsmsverifycode, JSON.stringify(data));
    setSeconds(60);

    // console.log('niuniu', resp)
  };

  return (
    <>
      {screenWidth > 780 ? (
        <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
          <div
            className="OfficialContainer"
            style={{
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(248, 248, 248, 1)',
            }}
          >
            <HeadBarPC />

            <div
              style={{
                transformOrigin: 'top',
                scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}`,
                width: '1224px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // height:`${1679*((screenWidth > 1920) ? 1 : screenWidth / 1920)}px`, minHeight:"100vh"
                position: 'absolute',
                top: '0',
              }}
            >
              <div style={{ width: '100%', marginTop: '200px' }}></div>

              <div style={{ width: '100%', marginBottom: '32px' }}>
                <p
                  style={{
                    fontSize: '24px',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  报名
                </p>
              </div>

              <div
                style={{
                  width: '100%',
                  /*height: '1268px',*/ borderRadius: '12px',
                  backgroundColor: 'white',
                  paddingTop: '80px',
                  paddingBottom: '80px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {(step === 1 || step === 2) && (
                  <>
                    <div
                      style={{
                        height: '24',
                        width: '340px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor:
                              step === 1 ? 'rgba(7, 193, 96, 1)' : '',
                            border:
                              step === 1
                                ? ''
                                : '0.5px solid rgba(0, 0, 0, 0.3)',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '14px',
                              fontWeight: '500',
                              color:
                                step === 1 ? 'white' : 'rgba(0, 0, 0, 0.3)',
                            }}
                          >
                            1
                          </p>
                        </div>
                        <p
                          style={{
                            fontSize: '14px',
                            marginLeft: '8px',
                            color: step === 1 ? 'rgba(7, 193, 96, 1)' : '',
                            fontWeight: '500',
                          }}
                        >
                          个人信息
                        </p>
                      </div>

                      <div style={{ color: 'rgba(0, 0, 0, 0.1)' }}>
                        ············
                      </div>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor:
                              step === 2 ? 'rgba(7, 193, 96, 1)' : '',
                            border:
                              step === 2
                                ? ''
                                : '0.5px solid rgba(0, 0, 0, 0.3)',
                          }}
                        >
                          <p
                            style={{
                              fontSize: '14px',
                              fontWeight: '500',
                              color:
                                step === 2 ? 'white' : 'rgba(0, 0, 0, 0.3)',
                            }}
                          >
                            2
                          </p>
                        </div>
                        <p
                          style={{
                            fontSize: '14px',
                            marginLeft: '8px',
                            color: step === 2 ? 'rgba(7, 193, 96, 1)' : '',
                            fontWeight: '500',
                          }}
                        >
                          检查报名内容
                        </p>
                      </div>
                    </div>

                    <div style={{ height: '24px' }}></div>
                    <div style={{ height: '40px' }}></div>
                    <div style={{ height: '24px' }}></div>
                  </>
                )}

                {step === 1 && (
                  <>
                    <div
                      style={{
                        width: '604px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      参赛成员信息
                    </div>
                    <div style={{ height: '24px' }}></div>
                    <div style={{ width: '604px' }}>
                      <Input
                        errMsg={signUpFormErrMsg.account}
                        desc="报名账号"
                        descWidth="124"
                        placeHolder="根据登录账号自动填入"
                        value={signUpForm.account}
                        showOutline={false}
                      />
                    </div>
                    <div style={{ height: '24px' }}></div>
                    <div style={{ width: '604px' }}>
                      <Input
                        errMsg={signUpFormErrMsg.name}
                        desc="姓名"
                        descWidth="124"
                        placeHolder="请输入姓名"
                        value={signUpForm.name}
                        onChange={(e) =>
                          handleInputChange('name', e.target.value)
                        }
                      />
                    </div>
                    <div style={{ height: '24px' }}></div>
                    <div style={{ width: '604px' }}>
                      <Input
                        errMsg={signUpFormErrMsg.school_name}
                        desc="所属学校"
                        descWidth="124"
                        placeHolder="请输入所属学校"
                        value={signUpForm.school_name}
                        onChange={(e) =>
                          handleInputChange('school_name', e.target.value)
                        }
                      />
                    </div>
                    <div style={{ height: '24px' }}></div>
                    <div style={{ width: '604px' }}>
                      <Input
                        errMsg={signUpFormErrMsg.id_number}
                        desc="身份证号"
                        descWidth="124"
                        placeHolder="请输入身份证号"
                        value={signUpForm.id_number}
                        onChange={(e) =>
                          handleInputChange('id_number', e.target.value)
                        }
                      />
                    </div>
                    <div style={{ height: '24px' }}></div>
                    <div
                      style={{
                        width: '604px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Input
                        errMsg={signUpFormErrMsg.phone_number}
                        desc="手机"
                        descWidth="124"
                        placeHolder="请输入手机号码"
                        value={signUpForm.phone_number}
                        onChange={(e) =>
                          handleInputChange('phone_number', e.target.value)
                        }
                      />
                      <div style={{ width: '16px' }}></div>
                      {/* <div
                                    style={{ flex: '0 0 129px', height: '40px', borderRadius: '8px', backgroundColor: 'rgba(0, 0, 0, 0.03)', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', fontSize: '14px', fontWeight: '400', cursor: 'pointer' }}
                                    onClick={sendsmsverifycode}
                                >发送验证码</div> */}
                      {seconds === 0 ? (
                        <p
                          style={{
                            flex: '0 0 70px',
                            fontSize: '14px',
                            color:
                              signUpForm.phone_number !== ''
                                ? 'rgb(101,119,156)'
                                : 'rgba(0,0,0,0.3)',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            sendsmsverifycode();
                          }}
                        >
                          获取验证码
                        </p>
                      ) : (
                        <p
                          style={{
                            flex: '0 0 85px',
                            fontSize: '14px',
                            color: 'rgba(0,0,0,0.3)',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }}
                        >
                          {seconds}S后可获取
                        </p>
                      )}
                    </div>
                    <div style={{ height: '24px' }}></div>
                    <div style={{ width: '604px' }}>
                      <Input
                        errMsg={signUpFormErrMsg.verifi_code}
                        desc="验证码"
                        descWidth="124"
                        placeHolder="请输入验证码"
                        value={signUpForm.verifi_code}
                        onChange={(e) =>
                          handleInputChange('verifi_code', e.target.value)
                        }
                      />
                    </div>
                    <div style={{ height: '24px' }}></div>

                    {memberList.length > 0 && renderMemberList()}
                    <div
                      style={{
                        width: '604px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        color: 'rgba(87, 107, 149, 1)',
                      }}
                    >
                      <div
                        onClick={addMember}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          color:
                            memberList.length == 2
                              ? 'rgba(0, 0, 0, 0.15)'
                              : 'rgba(87, 107, 149, 1)',
                        }}
                      >
                        <div
                          style={{
                            backgroundImage:
                              memberList.length == 2
                                ? `url(${preURL}/svg/plus-gray.svg)`
                                : `url(${preURL}/svg/plus.svg)`,
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                          }}
                        ></div>
                        添加团队成员
                      </div>
                    </div>
                    <div style={{ height: '24px' }}></div>

                    <div
                      style={{
                        width: '604px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      参赛团队信息
                    </div>

                    <div style={{ height: '24px' }}></div>
                    <div style={{ width: '604px' }}>
                      <RadioInputBox
                        errMsg={signUpFormErrMsg.group}
                        desc="分组"
                        descWidth="124"
                        placeHolder="选择"
                        value={signUpForm.group}
                        options={groupOptions}
                        onChange={(item: string) => {
                          handleInputChange('group', item);
                        }}
                      />
                    </div>
                    <div style={{ height: '24px' }}></div>

                    {/* <div style={{ width: '604px', height: '40px', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '600' }}>指导老师信息</div>
                                        <div style={{ height: '24px' }}></div> */}
                    <div style={{ width: '604px' }}>
                      <Input
                        errMsg={signUpFormErrMsg.teacher_name}
                        desc="指导老师姓名"
                        descWidth="124"
                        placeHolder="请输入指导老师姓名"
                        value={signUpForm.teacher_name}
                        onChange={(e) =>
                          handleInputChange('teacher_name', e.target.value)
                        }
                      />
                    </div>
                    <div style={{ height: '24px' }}></div>
                    <div style={{ width: '604px' }}>
                      <Input
                        errMsg={signUpFormErrMsg.teacher_phone_number}
                        desc="指导老师手机号"
                        descWidth="124"
                        placeHolder="请输入指导老师手机号"
                        value={signUpForm.teacher_phone_number}
                        onChange={(e) =>
                          handleInputChange(
                            'teacher_phone_number',
                            e.target.value,
                          )
                        }
                      />
                    </div>

                    <div style={{ height: '120px' }}></div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        text="取消"
                        onClick={() => {
                          navigate('/eventDetails');
                        }}
                      />
                      <div style={{ width: '12px' }}></div>
                      <Button
                        text="预览"
                        color="white"
                        backgroundColor="rgba(7, 193, 96, 1)"
                        onClick={async () => {
                          await nextStep();
                        }}
                      />
                    </div>
                  </>
                )}

                {step === 2 && (
                  <>
                    <div
                      style={{
                        width: '340px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      参赛成员信息
                    </div>
                    <div style={{ height: '24px' }}></div>
                    {renderResultItem('报名账号', signUpForm.account)}
                    <div style={{ height: '24px' }}></div>
                    {renderResultItem('姓名', signUpForm.name)}

                    <div style={{ height: '24px' }}></div>
                    {renderResultItem('所属学校', signUpForm.school_name)}

                    <div style={{ height: '24px' }}></div>
                    {renderResultItem('身份证号', signUpForm.id_number)}
                    <div style={{ height: '24px' }}></div>
                    {renderResultItem('手机', signUpForm.phone_number)}
                    <div style={{ height: '24px' }}></div>

                    {renderMemberList2()}
                    <div
                      style={{
                        width: '340px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      参赛团队信息
                    </div>

                    <div style={{ height: '24px' }}></div>
                    {renderResultItem('分组', signUpForm.group)}

                    <div style={{ height: '24px' }}></div>

                    {/* <div style={{ width: '340px', height: '40px', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '600' }}>指导老师信息</div>
                                        <div style={{ height: '24px' }}></div> */}
                    {renderResultItem('指导老师姓名', signUpForm.teacher_name)}

                    <div style={{ height: '24px' }}></div>
                    {renderResultItem(
                      '指导老师手机号',
                      signUpForm.teacher_phone_number,
                    )}

                    <div style={{ height: '120px' }}></div>

                    {/* <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', height: '20px' }}>
                                <CheckBox value={true} onChange={() => { }} />
                                <p style={{ marginLeft: '4px', marginRight: '4px' }}>我已阅读并同意</p>
                                <p style={{ color: 'rgba(87, 107, 149, 1)' }}>《参赛须知》</p>
                            </div>

                            <div style={{ height: '24px' }}></div> */}

                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          width: '120px',
                          height: '40px',
                          borderRadius: '8px',
                          backgroundColor: 'rgba(0, 0, 0, 0.03)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'black',
                          fontSize: '17px',
                          fontWeight: '500',
                          cursor: 'pointer',
                        }}
                        onClick={modeifyForm}
                      >
                        返回
                      </div>
                      <div style={{ width: '12px' }}></div>
                      <div
                        onClick={() => {
                          setShowCheckPcPopup(true);
                        }}
                        style={{
                          width: '120px',
                          height: '40px',
                          borderRadius: '8px',
                          backgroundColor: 'rgba(7, 193, 96, 1)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'white',
                          fontSize: '17px',
                          fontWeight: '500',
                          cursor: 'pointer',
                        }}
                      >
                        提交
                      </div>
                    </div>
                  </>
                )}

                {step === 3 && (
                  <>
                    <div style={{ height: '116px' }}></div>
                    <div
                      style={{
                        backgroundImage: `url(${preURL}/svg/check-circle2.svg)`,
                        width: '60px',
                        height: '60px',
                      }}
                    ></div>
                    <div style={{ height: '32px' }}></div>
                    <p style={{ fontSize: '24px', fontWeight: '500' }}>
                      已报名
                    </p>
                    {/* <div style={{ height: '8px' }}></div>
                                        <p style={{ fontSize: '14', color: 'rgba(0, 0, 0, 0.3)' }}>可进行作品提交，截止日期为8月31日</p> */}
                    <div style={{ height: '68px' }}></div>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        text="暂不提交"
                        width={120}
                        onClick={() => {
                          navigate('/eventDetails');
                        }}
                      />
                      <div style={{ width: '12px' }}></div>
                      <Button
                        text="提交作品"
                        width={120}
                        color="white"
                        backgroundColor="rgba(7,193,96,1)"
                        onClick={() => {
                          navigate('/commit');
                        }}
                      />
                    </div>
                    <div style={{ height: '116px' }}></div>
                  </>
                )}

                {step === 4 && (
                  <>
                    <div style={{ height: '116px' }}></div>
                    <div
                      style={{
                        backgroundImage: `url(${preURL}/svg/info-circle.svg)`,
                        width: '60px',
                        height: '60px',
                      }}
                    ></div>
                    <div style={{ height: '32px' }}></div>
                    <p style={{ fontSize: '24px', fontWeight: '500' }}>
                      报名失败
                    </p>
                    <div style={{ height: '8px' }}></div>
                    <p style={{ fontSize: '14', color: 'rgba(0, 0, 0, 0.3)' }}>
                      可尝试重新报名
                    </p>
                    <div style={{ height: '68px' }}></div>

                    <div
                      style={{
                        width: '147px',
                        height: '40px',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.03)',
                      }}
                    >
                      <p
                        style={{
                          color: 'rgba(0, 0, 0, 0.9)',
                          fontSize: '17px',
                          fontWeight: '500',
                        }}
                        onClick={() => {
                          navigate('/eventDetails');
                        }}
                      >
                        查看比赛信息
                      </p>
                    </div>
                    <div style={{ height: '116px' }}></div>
                  </>
                )}
              </div>

              <div
                style={{
                  fontSize: '14px',
                  fontWeight: '500',
                  color: 'rgba(0,0,0,0.3)',
                  marginBottom: '50px',
                  marginTop: '100px',
                }}
              >
                <CopyRightPC />
              </div>
            </div>

            {showCheckPcPopup && (
              <CommonPopup
                width={488}
                child={
                  <div
                    style={{
                      marginTop: '56px',
                      marginBottom: '64px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      fontSize: '20px',
                      color: 'rgba(0,0,0,0.9)',
                    }}
                  >
                    <p>提交后，报名信息将无法修改，</p>
                    <p>是否确认提交？</p>
                  </div>
                }
                cancelAction={() => {
                  setShowCheckPcPopup(false);
                }}
                cancelWord="取消"
                confirmAction={() => {
                  confirmForm();
                  setShowCheckPcPopup(false);
                }}
                confirmWord="提交"
              />
            )}
          </div>
        </div>
      ) : (
        <SignUpMobile
          step={step}
          signUpFormErrMsg={signUpFormErrMsg}
          signUpForm={signUpForm}
          handleInputChange={handleInputChange}
          seconds={seconds}
          sendsmsverifycode={sendsmsverifycode}
          memberList={memberList}
          // renderMemberList={renderMemberList}
          addMember={addMember}
          nextStep={nextStep}
          renderResultItem={renderResultItem}
          // renderMemberList2={renderMemberList2}
          modeifyForm={modeifyForm}
          confirmForm={confirmForm}
          deleteMember={deleteMember}
          memberListErrMsg={memberListErrMsg}
          handleMemberChange={handleMemberChange}
        />
      )}
    </>
  );
};

export default SignUp;
