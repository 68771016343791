import MultiEndedHoc from '../../components/MultiEndedHoc';
import OfficialDesktop from './desktop/Official';
import OfficialMobile from './mobile/Official';

const Official = () => {
  const WrappedComponent = MultiEndedHoc(OfficialDesktop, OfficialMobile);
  return <WrappedComponent />;
};

export default Official;
