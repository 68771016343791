import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMember, ISignUpForm, groupOptions } from '../SignUp';
import { RadioInputBox, Input } from '../../../components/Dialog/RegistDialog';
import { HeadBarMobile } from '../../../components/HeadBarMobile';
import { MobilePopup } from '../../../components/MobilePopup';
import { CopyRightMobile } from '../../login/mobile/Login';
import { UserStore, logout, useUserStore } from '../../../store';

interface ISignUpMobileProps {
  step: number;
  signUpFormErrMsg: ISignUpForm;
  signUpForm: ISignUpForm;
  handleInputChange: (item: string, val: string) => void;
  seconds: number;
  sendsmsverifycode: () => void;
  memberList: IMember[];
  // renderMemberList: () => any
  addMember: () => void;
  nextStep: () => void;
  renderResultItem: (k: string, v: string) => any;
  // renderMemberList2: () => any
  modeifyForm: () => void;
  confirmForm: () => void;
  deleteMember: (i: number) => void;
  memberListErrMsg: IMember[];
  handleMemberChange: (i: number, item: string, val: string) => void;
}
const preURL = process.env.PUBLIC_URL;

const SignUpMobile = (props: ISignUpMobileProps) => {
  const navigate = useNavigate();
  const userManager = useUserStore.getState() as UserStore;
  const [showCheckMobilePopup, setShowCheckMobilePopup] = useState(false);
  const [showLogoutMobilePopup, setShowLogoutMobilePopup] = useState(false);

  const {
    step,
    signUpFormErrMsg,
    signUpForm,
    handleInputChange,
    seconds,
    sendsmsverifycode,
    memberList,
    addMember,
    nextStep,
    modeifyForm,
    confirmForm,
    memberListErrMsg,
    deleteMember,
    handleMemberChange,
  } = props;

  const renderMemberList = () => {
    const list = memberList.map((item, index) => {
      return (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              团队成员{index + 1}
            </div>
            <p
              onClick={() => {
                deleteMember(index);
              }}
              style={{
                fontSize: '14px',
                color: 'rgba(87, 107, 149, 1)',
                fontWeight: '500',
                cursor: 'pointer',
              }}
            >
              删除
            </p>
          </div>

          <div style={{ height: '24px' }}></div>
          <div style={{ width: '100%' }}>
            <Input
              errMsg={memberListErrMsg[index].name}
              desc="姓名"
              descWidth="70"
              placeHolder="请输入姓名"
              value={memberList[index].name}
              onChange={(e) =>
                handleMemberChange(index, 'name', e.target.value)
              }
            />
          </div>
          <div style={{ height: '24px' }}></div>
          <div style={{ width: '100%' }}>
            <Input
              errMsg={memberListErrMsg[index].school_name}
              desc="所属学校"
              descWidth="70"
              placeHolder="请输入所属学校"
              value={memberList[index].school_name}
              onChange={(e) =>
                handleMemberChange(index, 'school_name', e.target.value)
              }
            />
          </div>
          <div style={{ height: '24px' }}></div>
          <div style={{ width: '100%' }}>
            <Input
              errMsg={memberListErrMsg[index].IDCard}
              desc="身份证号"
              descWidth="70"
              placeHolder="请输入身份证号"
              value={memberList[index].IDCard}
              onChange={(e) =>
                handleMemberChange(index, 'IDCard', e.target.value)
              }
            />
          </div>
          <div style={{ height: '24px' }}></div>
          <div style={{ width: '100%' }}>
            <Input
              errMsg={memberListErrMsg[index].mobile}
              desc="手机"
              descWidth="70"
              placeHolder="请输入手机号码"
              value={memberList[index].mobile}
              onChange={(e) =>
                handleMemberChange(index, 'mobile', e.target.value)
              }
            />
          </div>
          <div style={{ height: '24px' }}></div>
        </>
      );
    });

    return list;
  };
  const renderMemberList2 = () => {
    const list = memberList.map((item, index) => {
      return (
        <>
          <div
            style={{
              width: '100%',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            参赛成员{index + 1}
          </div>
          <div style={{ height: '24px' }}></div>

          {renderResultItem('姓名', item.name)}

          <div style={{ height: '24px' }}></div>
          {renderResultItem('所属学校', item.school_name)}

          <div style={{ height: '24px' }}></div>
          {renderResultItem('身份证号', item.IDCard)}
          <div style={{ height: '24px' }}></div>
          {renderResultItem('手机', item.mobile)}
          <div style={{ height: '24px' }}></div>
        </>
      );
    });

    return list;
  };

  const renderResultItem = (k: string, v: string) => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          minHeight: '40px',
        }}
      >
        <div
          style={{
            flex: '0 0 124px',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.55)',
          }}
        >
          {k}
        </div>
        <div
          style={{
            color: 'rgba(0,0,0,0.9)',
            fontSize: '14px',
            fontWeight: '400',
            maxWidth: '236px',
          }}
        >
          {v}
        </div>
      </div>
    );
  };

  return (
    <div
      className="CompetitionContainer"
      style={{
        width: '100%',
        backgroundColor: 'rgb(248,248,248)',
        position: 'relative',
        minHeight: '100vh',
      }}
    >
      {showCheckMobilePopup && (
        <MobilePopup
          word="提交信息后将无法修改"
          cancelWord="取消"
          confirmAction={() => {
            confirmForm();
            setShowCheckMobilePopup(false);
          }}
          cancelAction={() => {
            setShowCheckMobilePopup(false);
          }}
          confirmWord="提交"
        />
      )}

      {showLogoutMobilePopup && (
        <MobilePopup
          word={`退出账号 ${userManager.account} ?`}
          cancelWord="取消"
          confirmAction={() => {
            logout();
            navigate('/eventDetails');
            setShowLogoutMobilePopup(false);
          }}
          cancelAction={() => {
            setShowLogoutMobilePopup(false);
          }}
          confirmWord="退出"
        />
      )}
      <HeadBarMobile
        word="双城青少年编程创新赛"
        leftClick={() => {
          navigate('/eventDetails');
        }}
        rightAction={userManager.ticket !== '' ? userManager.account : '登录'}
        rightClick={() => {
          if (userManager.ticket !== '') {
            // 退出登录提示框
            setShowLogoutMobilePopup(true);
          } else {
            navigate('/loginMobile');
          }
        }}
      />
      <div style={{ height: '40px' }}></div>
      <div
        style={{
          transformOrigin: 'top',
          padding: '0 18px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ width: '100%', marginBottom: '32px' }}>
          <p style={{ fontSize: '20px', color: 'black', fontWeight: '500' }}>
            报名
          </p>
        </div>

        <div
          style={{
            width: '100%',
            borderRadius: '12px',
            backgroundColor: 'white',
            border: '0.5px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '32px 16px',
          }}
        >
          {(step === 1 || step === 2) && (
            <>
              <div
                style={{
                  height: '24',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '12px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: step === 1 ? 'rgba(7, 193, 96, 1)' : '',
                      border:
                        step === 1 ? '' : '0.5px solid rgba(0, 0, 0, 0.3)',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: step === 1 ? 'white' : 'rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      1
                    </p>
                  </div>
                  <p
                    style={{
                      fontSize: '14px',
                      marginLeft: '8px',
                      color: step === 1 ? 'rgba(7, 193, 96, 1)' : '',
                    }}
                  >
                    报名信息
                  </p>
                </div>

                <div style={{ color: 'rgba(0, 0, 0, 0.1)', margin: '0 10px' }}>
                  ········
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '12px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: step === 2 ? 'rgba(7, 193, 96, 1)' : '',
                      border:
                        step === 2 ? '' : '0.5px solid rgba(0, 0, 0, 0.3)',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        color: step === 2 ? 'white' : 'rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      2
                    </p>
                  </div>
                  <p
                    style={{
                      fontSize: '14px',
                      marginLeft: '8px',
                      color: step === 2 ? 'rgba(7, 193, 96, 1)' : '',
                    }}
                  >
                    检查信息
                  </p>
                </div>
              </div>

              <div style={{ height: '40px' }}></div>
            </>
          )}

          {step === 1 && (
            <>
              <div
                style={{
                  width: '100%',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                参赛成员信息
              </div>
              <div style={{ height: '24px' }}></div>
              <div style={{ width: '100%' }}>
                <Input
                  errMsg={signUpFormErrMsg.account}
                  desc="报名账号"
                  descWidth="70"
                  placeHolder="根据登录账号自动填入"
                  value={signUpForm.account}
                  showOutline={false}
                />
              </div>
              <div style={{ height: '24px' }}></div>
              <div style={{ width: '100%' }}>
                <Input
                  errMsg={signUpFormErrMsg.name}
                  desc="姓名"
                  descWidth="70"
                  placeHolder="请输入姓名"
                  value={signUpForm.name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                />
              </div>
              <div style={{ height: '24px' }}></div>
              <div style={{ width: '100%' }}>
                <Input
                  errMsg={signUpFormErrMsg.school_name}
                  desc="所属学校"
                  descWidth="70"
                  placeHolder="请输入所属学校"
                  value={signUpForm.school_name}
                  onChange={(e) =>
                    handleInputChange('school_name', e.target.value)
                  }
                />
              </div>
              <div style={{ height: '24px' }}></div>
              <div style={{ width: '100%' }}>
                <Input
                  errMsg={signUpFormErrMsg.id_number}
                  desc="身份证号"
                  descWidth="70"
                  placeHolder="请输入身份证号"
                  value={signUpForm.id_number}
                  onChange={(e) =>
                    handleInputChange('id_number', e.target.value)
                  }
                />
              </div>
              <div style={{ height: '24px' }}></div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Input
                  errMsg={signUpFormErrMsg.phone_number}
                  desc="手机"
                  descWidth="70"
                  placeHolder="请输入手机号码"
                  value={signUpForm.phone_number}
                  onChange={(e) =>
                    handleInputChange('phone_number', e.target.value)
                  }
                />
                <div style={{ width: '16px' }}></div>
                {/* <div
                    style={{ flex: '0 0 129px', height: '40px', borderRadius: '8px', backgroundColor: 'rgba(0, 0, 0, 0.03)', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', fontSize: '14px', fontWeight: '400', cursor: 'pointer' }}
                    onClick={sendsmsverifycode}
                >发送验证码</div> */}
                {seconds === 0 ? (
                  <p
                    style={{
                      flex: '0 0 70px',
                      fontSize: '14px',
                      color:
                        signUpForm.phone_number !== ''
                          ? 'rgb(101,119,156)'
                          : 'rgba(0,0,0,0.3)',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      sendsmsverifycode();
                    }}
                  >
                    获取验证码
                  </p>
                ) : (
                  <p
                    style={{
                      flex: '0 0 82px',
                      fontSize: '14px',
                      color: 'rgba(0,0,0,0.3)',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    {seconds}S后可获取
                  </p>
                )}
              </div>
              <div style={{ height: '24px' }}></div>
              <div style={{ width: '100%' }}>
                <Input
                  errMsg={signUpFormErrMsg.verifi_code}
                  desc="验证码"
                  descWidth="70"
                  placeHolder="请输入验证码"
                  value={signUpForm.verifi_code}
                  onChange={(e) =>
                    handleInputChange('verifi_code', e.target.value)
                  }
                />
              </div>
              <div style={{ height: '24px' }}></div>

              {memberList.length > 0 && renderMemberList()}
              <div
                style={{
                  width: '100%',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  color: 'rgba(87, 107, 149, 1)',
                }}
              >
                <div
                  onClick={addMember}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    color:
                      memberList.length == 2
                        ? 'rgba(0, 0, 0, 0.15)'
                        : 'rgba(87, 107, 149, 1)',
                  }}
                >
                  <div
                    style={{
                      backgroundImage:
                        memberList.length == 2
                          ? `url(${preURL}/svg/plus-gray.svg)`
                          : `url(${preURL}/svg/plus.svg)`,
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                  ></div>
                  添加团队成员
                </div>
              </div>
              <div style={{ height: '24px' }}></div>

              <div
                style={{
                  width: '100%',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                参赛团队信息
              </div>

              <div style={{ height: '24px' }}></div>
              <div style={{ width: '100%' }}>
                <RadioInputBox
                  errMsg={signUpFormErrMsg.group}
                  desc="分组"
                  descWidth="70"
                  placeHolder="选择"
                  value={signUpForm.group}
                  options={groupOptions}
                  onChange={(item: string) => {
                    handleInputChange('group', item);
                  }}
                />
              </div>
              <div style={{ height: '24px' }}></div>

              {/* <div style={{ width: '100%', height: '40px', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '600' }}>指导老师信息</div>
              <div style={{ height: '24px' }}></div> */}
              <div style={{ width: '100%' }}>
                <Input
                  errMsg={signUpFormErrMsg.teacher_name}
                  desc="姓名"
                  descWidth="70"
                  placeHolder="请输入指导老师姓名"
                  value={signUpForm.teacher_name}
                  onChange={(e) =>
                    handleInputChange('teacher_name', e.target.value)
                  }
                />
              </div>
              <div style={{ height: '24px' }}></div>
              <div style={{ width: '100%' }}>
                <Input
                  errMsg={signUpFormErrMsg.teacher_phone_number}
                  desc="联系方式"
                  descWidth="70"
                  placeHolder="请输入指导老师手机号"
                  value={signUpForm.teacher_phone_number}
                  onChange={(e) =>
                    handleInputChange('teacher_phone_number', e.target.value)
                  }
                />
              </div>

              <div style={{ height: '40px' }}></div>
              <div
                style={{
                  width: '120px',
                  height: '40px',
                  borderRadius: '8px',
                  backgroundColor: 'rgba(7, 193, 96, 1)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '17px',
                  fontWeight: '500',
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  await nextStep();
                }}
              >
                下一步
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div
                style={{
                  width: '100%',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                参赛成员信息
              </div>
              <div style={{ height: '24px' }}></div>
              {renderResultItem('报名账号', signUpForm.account)}
              <div style={{ height: '24px' }}></div>
              {renderResultItem('姓名', signUpForm.name)}

              <div style={{ height: '24px' }}></div>
              {renderResultItem('所属学校', signUpForm.school_name)}

              <div style={{ height: '24px' }}></div>
              {renderResultItem('身份证号', signUpForm.id_number)}
              <div style={{ height: '24px' }}></div>
              {renderResultItem('手机', signUpForm.phone_number)}
              <div style={{ height: '24px' }}></div>

              {renderMemberList2()}
              <div
                style={{
                  width: '100%',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                参赛团队信息
              </div>

              <div style={{ height: '24px' }}></div>
              {renderResultItem('分组', signUpForm.group)}

              <div style={{ height: '24px' }}></div>

              {/* <div style={{ width: '100%', height: '40px', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '600' }}>指导老师信息</div>
              <div style={{ height: '24px' }}></div> */}
              {renderResultItem('姓名', signUpForm.teacher_name)}

              <div style={{ height: '24px' }}></div>
              {renderResultItem('联系方式', signUpForm.teacher_phone_number)}

              <div style={{ height: '40px' }}></div>

              {/* <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', height: '20px' }}>
                <CheckBox value={true} onChange={() => { }} />
                <p style={{ marginLeft: '4px', marginRight: '4px' }}>我已阅读并同意</p>
                <p style={{ color: 'rgba(87, 107, 149, 1)' }}>《参赛须知》</p>
            </div>

            <div style={{ height: '24px' }}></div> */}

              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: '120px',
                    height: '40px',
                    borderRadius: '8px',
                    backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'black',
                    fontSize: '17px',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                  onClick={modeifyForm}
                >
                  修改
                </div>
                <div style={{ width: '12px' }}></div>
                <div
                  onClick={() => {
                    setShowCheckMobilePopup(true);
                    // confirmForm()
                  }}
                  style={{
                    width: '120px',
                    height: '40px',
                    borderRadius: '8px',
                    backgroundColor: 'rgba(7, 193, 96, 1)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    fontSize: '17px',
                    fontWeight: '500',
                    cursor: 'pointer',
                  }}
                >
                  提交
                </div>
              </div>
            </>
          )}

          {step === 3 && (
            <>
              <div style={{ height: '32px' }}></div>
              <div
                style={{
                  backgroundImage: `url(${preURL}/svg/check-circle2.svg)`,
                  width: '60px',
                  height: '60px',
                }}
              ></div>
              <div style={{ height: '32px' }}></div>
              <p style={{ fontSize: '20px', fontWeight: '500' }}>报名成功</p>

              <div style={{ height: '273px' }}></div>
              <div
                style={{
                  width: '147px',
                  height: '40px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                }}
              >
                <p
                  style={{
                    color: 'rgba(0, 0, 0, 0.9)',
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                  onClick={() => {
                    navigate('/eventDetails');
                  }}
                >
                  查看比赛信息
                </p>
              </div>
              {/* <div style={{ height: '280px' }}></div> */}
            </>
          )}

          {step === 4 && (
            <>
              {/* <div style={{ height: '280px' }}></div> */}
              <div
                style={{
                  backgroundImage: `url(${preURL}/svg/info-circle.svg)`,
                  width: '60px',
                  height: '60px',
                }}
              ></div>
              <div style={{ height: '32px' }}></div>
              <p style={{ fontSize: '20px', fontWeight: '500' }}>报名失败</p>
              <div style={{ height: '8px' }}></div>
              <p style={{ fontSize: '14', color: 'rgba(0, 0, 0, 0.3)' }}>
                可尝试重新报名
              </p>
              <div style={{ height: '40px' }}></div>

              <div
                style={{
                  width: '147px',
                  height: '40px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                }}
              >
                <p
                  style={{
                    color: 'rgba(0, 0, 0, 0.9)',
                    fontSize: '17px',
                    fontWeight: '500',
                  }}
                  onClick={() => {
                    navigate('/eventDetails');
                  }}
                >
                  查看比赛信息
                </p>
              </div>
              {/* <div style={{ height: '280px' }}></div> */}
            </>
          )}
        </div>

        <div style={{ height: '120px' }}></div>
        <div style={{ position: 'absolute', bottom: '40px', width: '100%' }}>
          <CopyRightMobile />
        </div>
      </div>
    </div>
  );
};

export default SignUpMobile;
