import { memo } from 'react';
import React, { useEffect, useState } from 'react';
import './index.scss';
import {
  ApplicationType,
  getDownloadFileName,
  PLATFORM_TO_NAME,
  PLATFORM_TO_NAME_MD,
} from '../../config/utils';
import ReactMarkdown from 'react-markdown';

function useDownloadPrefix() {
  const [prefix, setPrefix] = useState(
    'https://dldir1.qq.com/WechatWebDev/nightly-edu/p-3bd19c2db3a642a0b39af853efaf67f8/0.54.1/',
  );
  const jsonUrl =
    'https://dldir1.qq.com/WechatWebDev/nightly-edu/versioninfo/0.54.1/version.json'; // https://dldir1.qq.com/WechatWebDev/nightly-edu/versioninfo/0.54.1/version.json
  fetch(jsonUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      if (data.update_url_prefix) {
        setPrefix(data.update_url_prefix.replace('0.54.1-173', '0.54.1'));
      }
    });

  return [prefix, setPrefix];
}

// @ts-ignore
export default memo(() => {
  const [prefix] = useDownloadPrefix();
  const [content, setContent] = useState('');
  const [versions, setVersions] = useState({});
  useEffect(() => {
    //根据接口拉 cos 的 version json
    fetchHtmlContent();
  }, [prefix]);

  const platforms = [
    ApplicationType.win64,
    ApplicationType.win32,
    ApplicationType.darwin64,
    ApplicationType.darwinArm,
  ];

  const fetchHtmlContent = async () => {
    try {
      const response = await fetch(
        'https://testchu-7gy8occc8dcc14c3-1304825656.tcloudbaseapp.com/weapp-edu/nightly-edu-version-page/version.json',
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const versionmap = await response.json();
      setVersions(versionmap);
      // const htmlString = await response.text();
      // const $ = cheerio.load(htmlString);
      // const contentNode = $(".content.custom");
      // contentNode.find(".header-anchor").remove();
      // contentNode.find("script").remove();
      // const vs: string[] = []
      // const subTitleNode = contentNode.find("blockquote")[0]
      // $(subTitleNode).empty()
      // $(subTitleNode).append(`<p>教育场景版本，对齐开发者工具 Stable Build 的特性和缺陷修复</p>`)
      // contentNode.find("h3[id]").each((index: any, element: any) => {
      //     if (index === 0) {
      //         $(element).remove()
      //         return
      //     }
      //     vs.push($(element).text());
      //     const as = platforms.map(p => {
      //         return `<a target="_blank" key="${p}" href="${prefix}${getDownloadFileName($(element).text().trim(), p)}">${PLATFORM_TO_NAME[p]}</a>`
      //     })
      //     $(element).append(`
      //         ${as.join('')}
      //     `)
      // });
      // // @ts-ignore
      // setContent(contentNode.html());
    } catch (error) {
      console.error('Error fetching HTML content:', error);
    }
  };

  return (
    <div className="version-wrapper">
      <div className="content">
        <h2 id="微信开发者工具-教育版-Edu-Build-下载地址与更新日志">
          微信开发者工具(教育版 Edu Build)
          <blockquote>
            <p>教育场景版本，对齐开发者工具 Stable Build 的特性和缺陷修复</p>
          </blockquote>
          下载地址与更新日志
        </h2>
        {Object.values(versions).map((v: any) => {
          return (
            <>
              <h3>
                {v.version}{' '}
                {platforms.map((p) => {
                  return (
                    <a
                      target="_blank"
                      key={p}
                      href={`${prefix}${getDownloadFileName(
                        v.version.trim(),
                        p,
                      )}`}
                    >
                      {PLATFORM_TO_NAME_MD[p]}
                    </a>
                  );
                })}
              </h3>
              <ReactMarkdown children={v.infos.join('\n')} />
            </>
          );
        })}
      </div>
    </div>
  );
});
