import { useEffect, useState } from 'react';
import { Button, Input } from './RegistDialog';
import { sha256 } from '../../config/utils';
import axios from 'axios';
import urlConfigs from '../../config/urlList';
import { login, useDeviceStore, useDialogStore } from '../../store';
import { useIntl } from 'react-intl';
import { DIALOG_ID } from '../../typing/global';
import { EduSvgCollection } from '../SvgManager';

interface ILoginDialogProps {}

export function LoginDialog(props: ILoginDialogProps) {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  const [accountErrMsg, setAccountErrMsg] = useState('');
  const [pwdErrMsg, setPwdErrMsg] = useState('');

  const intl = useIntl();

  const { screenWidth } = useDeviceStore((state) => state);

  const dialogStore = useDialogStore((state) => state);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleLogin = async () => {
    setAccountErrMsg('');
    setPwdErrMsg('');

    const data = {
      account_name: account,
      account_pwd: sha256(password),
    };
    const resp = await axios.post(
      urlConfigs.loginbyaccount,
      JSON.stringify(data),
    );
    // console.log('niu-resp', resp)
    if (resp.data.errcode === 0) {
      // 存到store里面
      login(
        resp.data.eduticket,
        resp.data.account_name,
        resp.data.account_type,
        Date.now() + resp.data.eduticket_expire_time * 1000,
      );
      // 这里应该不需要调用，因为login会触发CompetitionItem的useEffect
      // if (getPerticipationInfo) {
      //   getPerticipationInfo();
      // }
      dialogStore.closeDialog(DIALOG_ID.LOGIN);
    } else if (resp.data.errcode === 100135) {
      //账号不对
      setAccountErrMsg('账号不存在');
    } else if (resp.data.errcode === 100136) {
      // 授权码不对
      setPwdErrMsg('授权码错误');
    }
  };

  const handleClose = () => {
    dialogStore.closeDialog(DIALOG_ID.LOGIN);
  };
  return (
    <div className="login-dialog dialog-top">
      <div style={{ scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}` }}>
        <div
          style={{
            width: '480px',
            padding: '32px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
            alignItems: 'center',
            borderRadius: '16px',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '28px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p style={{ fontSize: '20px', fontWeight: '500' }}>
              {intl.formatMessage({
                id: 'login.login',
              })}
            </p>
            <EduSvgCollection.Close
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <div style={{ height: '40px' }}></div>
          <Input
            errMsg={accountErrMsg}
            desc={intl.formatMessage({
              id: 'login.account',
            })}
            descWidth="100"
            placeHolder={intl.formatMessage({
              id: 'login.enterAccount',
            })}
            value={account}
            onChange={(e) => {
              setAccount(e.target.value);
            }}
          />
          <div style={{ height: '24px' }}></div>

          <Input
            errMsg={pwdErrMsg}
            desc={intl.formatMessage({
              id: 'login.password',
            })}
            descWidth="100"
            placeHolder={intl.formatMessage({
              id: 'login.enterPassword',
            })}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div style={{ height: '112px' }}></div>

          <Button
            onClick={handleLogin}
            text={intl.formatMessage({
              id: 'login.login',
            })}
            backgroundColor="rgba(7, 193, 96, 1)"
            color="white"
          />
          <div style={{ height: '16px' }}></div>

          {/* <div onClick={() => { userManager.clearTicket() }}>{userManager.ticket}</div> */}
          <p
            style={{
              fontSize: '14px',
              fontWeight: '500',
              color: 'rgba(87, 107, 149, 1)',
              cursor: 'pointer',
            }}
            onClick={() => {
              dialogStore.closeDialog(DIALOG_ID.LOGIN);
              dialogStore.openDialog(DIALOG_ID.REGIST);
            }}
          >
            {intl.formatMessage({
              id: 'login.register',
            })}
          </p>
        </div>
      </div>
    </div>
  );
}
