import React, { useEffect, useState } from 'react';
import {
  UserStore,
  useDeviceStore,
  useDialogStore,
  useUserStore,
} from '../../../store';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import urlConfigs from '../../../config/urlList';
import { sendLog } from '../../../config/report';
import { CopyRightPC } from '../../login/mobile/Login';
import { HeadBarPC } from '../../../components/HeadBar/HeadBarPC';
import './CompetitionItem.css';
import { DIALOG_ID, ParticipationStatus } from '../../../typing/global';
import {
  COMPETITION_TIMELINE,
  COMPETITION_DETAIL,
  CompetitionStage,
  COMPETITION_MESSAGE,
  CompetitionMessage,
} from '../../../config/competition';
import classNames from 'classnames';
import { EduSvgCollection } from '../../../components/SvgManager';

export const CompetitionTitle = (props: { title: string; style?: Object }) => {
  const { title, style } = props;
  return (
    <div
      key={title}
      style={Object.assign({ width: '100%' }, style)}
    >
      <div className="competition-item-desktop__title">
        <div className="competition-item-desktop__title__blue-point"></div>
        <span>{title}</span>
        <div className="competition-item-desktop__title__green-point"></div>
      </div>
    </div>
  );
};

const CompetitionProgress = () => {
  const ProgressItem = (props: {
    stageName: string;
    time: string;
    type: 'start' | 'middle' | 'end';
  }) => {
    const { stageName, time, type } = props;
    return (
      <div className="competition-item-desktop__progress__stage">
        <span>{stageName}</span>
        <div className="competition-item-desktop__progress__stage-box">
          <div className="competition-item-desktop__progress__stage-point"></div>
          <div
            className={classNames({
              'competition-item-desktop__progress__stage-line': true,
              'competition-item-desktop__progress__stage-line-start':
                type === 'start',
              'competition-item-desktop__progress__stage-line-end':
                type === 'end',
            })}
          ></div>
        </div>
        <span>{time}</span>
      </div>
    );
  };
  return (
    <div className="competition-item-desktop__gray-wrap competition-item-desktop__progress">
      {COMPETITION_TIMELINE.map((stage: CompetitionStage) => {
        const { type, stageName, time } = stage;
        return (
          <ProgressItem
            key={stageName}
            type={type}
            stageName={stageName}
            time={time}
          />
        );
      })}
      {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '158px' }}>
    <span style={{ fontSize: '17px', fontWeight: '400', color: 'rgba(153,153,153,1)' }}>*赛事具体时间安排或因实际情况而调整，具体见大赛主办方通知</span>
  </div> */}
    </div>
  );
};

const CompetitionMessageItem = (message: CompetitionMessage) => {
  const { type, url, title } = message;
  const handleNavigation = () => {
    window.open(url);
  };
  return (
    <div
      className="competition-item-desktop__message-item"
      onClick={handleNavigation}
    >
      {/* todo: icon */}
      {title}
    </div>
  );
};

const DetailParagraph = (props: { title: string; content: string[] }) => {
  const { title, content } = props;
  return (
    <>
      <div className="competition-item-desktop__detail__title">
        <div className="competition-item-desktop__detail__title-bar"></div>
        {title}
      </div>
      {content.map((text, index) => {
        return <p key={title + index}>{text}</p>;
      })}
    </>
  );
};

const CompetitionItem = () => {
  const { screenWidth } = useDeviceStore((state) => state);

  const [parted, setParted] = useState(false);
  const [committed, setCommitted] = useState(false);
  const navigate = useNavigate();
  const preURL = process.env.PUBLIC_URL;
  const userManager = useUserStore((state) => state) as UserStore;
  const dialogStore = useDialogStore((state) => state);

  const getPerticipationInfo = async () => {
    const data = {
      competition_uuid: '20240620',
    };
    const url = `${urlConfigs.edudev_getparticipationinfo}?eduticket=${userManager.ticket}`;

    try {
      const resp = await axios.post(url, JSON.stringify(data));

      if (resp.data.errcode === 0 && resp.data.participation_info) {
        if (
          resp.data.participation_info.status === ParticipationStatus.parted
        ) {
          setParted(true);
        }
        if (
          resp.data.participation_info.status === ParticipationStatus.submitted
        ) {
          setParted(true);
          setCommitted(true);
        }
      } else {
        setParted(false);
        setCommitted(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const goForMoreDetail = () => {
    window.open(
      'https://doc.weixin.qq.com/pdf/d3_AZUAUgb5AMIR9rSC5TmRaSgsYYcOh?scode=AJEAIQdfAAo6xCXfAeAZUAUgb5AMI',
    );
  };

  useEffect(() => {
    getPerticipationInfo();
  }, [userManager.ticket]);

  useEffect(() => {
    sendLog({ method: '官网赛事页面曝光', platform: 'PC' });
  }, []);

  return (
    <div className="competition-item-desktop">
      <HeadBarPC />

      <div
        style={{
          maxHeight: '815px',
          height: `${(screenWidth / 1920) * 815}px`,
          width: `${
            screenWidth > 1920 ? `${(1920 / screenWidth) * 100}%` : '100%'
          }`,
          backgroundImage: `url('${preURL}/img/pc/comp2.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          position: 'absolute',
        }}
      ></div>

      <div
        style={{
          transformOrigin: 'top',
          scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}`,
          width: '1224px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          top: '0',
        }}
      >
        <div
          style={{
            width: '100%',
            marginBottom: '400px',
            marginTop: '450px',
          }}
        >
          {parted ? (
            <div style={{ display: 'flex' }}>
              {!committed && (
                <div
                  onClick={() => {
                    // sendLog({ method: '官网赛事页面点击查看报名信息', platform: 'PC' })
                    if (userManager.ticket !== '') {
                      navigate('/commit');
                    }
                  }}
                  style={{
                    height: '56px',
                    width: '152px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(7, 193, 96, 1)',
                    color: 'white',
                    fontSize: '20px',
                    fontWeight: '500',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    marginRight: '18px',
                  }}
                >
                  提交作品
                </div>
              )}
              <div
                onClick={() => {
                  sendLog({
                    method: '官网赛事页面点击查看报名信息',
                    platform: 'PC',
                  });
                  if (userManager.ticket !== '') {
                    navigate('/participationInfo');
                  }
                }}
                style={{
                  height: '56px',
                  width: '152px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0,0,0,0.03)',
                  color: 'rgba(0,0,0,0.9)',
                  fontSize: '20px',
                  fontWeight: '500',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
              >
                我的参赛信息
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                sendLog({
                  method: '官网赛事页面点击立即报名',
                  platform: 'PC',
                });

                if (userManager.ticket !== '') {
                  navigate('/signUp');
                } else {
                  dialogStore.openDialog(DIALOG_ID.LOGIN);
                  // alert('请先登录')
                }
              }}
              style={{
                height: '56px',
                width: '152px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(7, 193, 96, 1)',
                color: 'white',
                fontSize: '20px',
                fontWeight: '500',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              立即报名
            </div>
          )}
        </div>

        <CompetitionTitle
          title="关键时间"
          style={{ marginBottom: '32px' }}
        />
        <CompetitionProgress />

        <CompetitionTitle
          title="赛事详情"
          style={{ marginBottom: '32px', marginTop: '100px' }}
        />
        <div className="competition-item-desktop__gray-wrap competition-item-desktop__detail">
          {COMPETITION_DETAIL.map((pharagraph) => {
            return (
              <DetailParagraph
                key={pharagraph.title}
                title={pharagraph.title}
                content={pharagraph.content}
              />
            );
          })}
          <div
            className="competition-item-desktop__more-detail"
            onClick={goForMoreDetail}
          >
            更多赛事详情
            <EduSvgCollection.ArrowRight />
          </div>
        </div>

        <CompetitionTitle
          title="比赛动态"
          style={{ marginBottom: '32px', marginTop: '100px' }}
        />
        <div className="competition-item-desktop__gray-wrap competition-item-desktop__message">
          {COMPETITION_MESSAGE.map((message) => {
            return (
              <CompetitionMessageItem
                key={message.title}
                type={message.type}
                url={message.url}
                title={message.title}
              />
            );
          })}
        </div>

        <div style={{ marginBottom: '50px', marginTop: '100px' }}>
          <CopyRightPC />
        </div>
      </div>
    </div>
  );
};

export default CompetitionItem;
