import { ReactComponent as ArrowLeft } from '../assets/svg/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../assets/svg/arrow-right.svg';
import { ReactComponent as Close } from '../assets/svg/close.svg';
import { ReactComponent as Light } from '../assets/svg/light.svg';
import { ReactComponent as PlayVideo } from '../assets/svg/play-video.svg';
import { ReactComponent as Teacher } from '../assets/svg/teacher.svg';
import { ReactComponent as TeacherGray } from '../assets/svg/teacher-gray.svg';
import { ReactComponent as Student } from '../assets/svg/student.svg';
import { ReactComponent as StudentGray } from '../assets/svg/student-gray.svg';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';

export const EduSvgCollection = {
  ArrowLeft,
  ArrowRight,
  Close,
  Light,
  PlayVideo,
  Teacher,
  TeacherGray,
  Student,
  StudentGray,
  Logo,
};
