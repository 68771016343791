import { HeadBarMobile } from '../../../components/HeadBarMobile';
import { useNavigate } from 'react-router-dom';
import { CopyRightMobile } from '../../login/mobile/Login';
import { FormattedMessage } from 'react-intl';

const RegistMobile = () => {
  const navigate = useNavigate();

  return (
    <div
      className="CompetitionContainer"
      style={{
        width: '100%',
        backgroundColor: 'rgb(248,248,248)',
        position: 'relative',
        height: '100vh',
      }}
    >
      <HeadBarMobile
        word="双城青少年编程创新赛"
        leftClick={() => {
          navigate('/eventDetails');
        }}
        rightAction=""
        rightClick={() => {}}
      />

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '40%',
        }}
      >
        <p style={{ color: 'black', fontSize: '22px' }}>
          <FormattedMessage
            id="regist.redirectUrl"
            description="请使用电脑打开以下网址注册"
            defaultMessage="请使用电脑打开以下网址注册"
          />
        </p>
        <div style={{ height: '16px' }}></div>
        <p style={{ color: 'rgba(0,0,0,0.9)', fontSize: '17px' }}>
          https://edu.weixin.qq.com/
        </p>
      </div>

      <div style={{ position: 'absolute', bottom: '40px', width: '100%' }}>
        <CopyRightMobile />
      </div>
    </div>
  );
};

export default RegistMobile;
