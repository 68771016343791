import React, { useEffect, useState } from 'react';
import urlConfigs from '../../config/urlList';
import axios from 'axios';
import { UserStore, useDeviceStore, useUserStore } from '../../store';
import { useNavigate } from 'react-router-dom';
import ParticipationInfoMobile from './mobile/ParticipationInfo';
import { ParticipationStatus } from '../../typing/global';
import { CopyRightPC } from '../login/mobile/Login';
import { HeadBarPC } from '../../components/HeadBar/HeadBarPC';
import { formatTimestamp } from '../../config/utils';

const ParticipationInfo = () => {
  const { screenWidth, setScreenWidth } = useDeviceStore();
  const [titleFixed, setTitleFixed] = useState(false);
  const [showTitleButton, setShowTitleButton] = useState(false);
  const navigate = useNavigate();
  const preURL = process.env.PUBLIC_URL;
  const [showLogout, setShowLogout] = useState(false);

  const userManager = useUserStore.getState() as UserStore;

  const [info, setInfo] = useState<any>({});
  const [memberInfo, setMemberInfo] = useState<any[]>([]);

  const [committed, setCommitted] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    getPerticipationInfo();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = 'rgba(248, 248, 248, 1)';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const getPerticipationInfo = async () => {
    const data = {
      competition_uuid: '20240620',
    };
    const url = `${urlConfigs.edudev_getparticipationinfo}?eduticket=${userManager.ticket}`;

    try {
      const resp = await axios.post(url, JSON.stringify(data));

      if (resp.data.errcode === 0 && resp.data.participation_info) {
        setInfo(resp.data.participation_info);
        setMemberInfo(resp.data.member_info);
        if (
          resp.data.participation_info.status === ParticipationStatus.submitted
        ) {
          setCommitted(true);
        }
      } else {
        setCommitted(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const renderResultItem = (k: string, v: string) => {
    return (
      <div
        style={{
          width: committed ? '' : '340px',
          display: 'flex',
          alignItems: 'center',
          minHeight: '40px',
        }}
      >
        <div
          style={{
            flex: '0 0 124px',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.55)',
          }}
        >
          {k}
        </div>
        <div
          style={{
            color: 'rgba(0,0,0,0.9)',
            fontSize: '14px',
            fontWeight: '400',
            maxWidth: '236px',
          }}
        >
          {v}
        </div>
      </div>
    );
  };

  const renderMemberList = () => {
    const list = memberInfo.map((item, index) => {
      return (
        <>
          <div
            style={{
              width: committed ? '' : '340px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: '600',
            }}
          >
            参赛成员{index + 1}
          </div>
          <div style={{ height: committed ? '12px' : '24px' }}></div>

          {renderResultItem('姓名', item.name)}

          <div style={{ height: committed ? '12px' : '24px' }}></div>
          {renderResultItem('所属学校', item.school_name)}

          <div style={{ height: committed ? '12px' : '24px' }}></div>
          {renderResultItem('身份证号', item.IDCard)}
          <div style={{ height: committed ? '12px' : '24px' }}></div>
          {renderResultItem('手机', item.mobile)}
          <div style={{ height: committed ? '12px' : '24px' }}></div>
        </>
      );
    });

    return list;
  };

  return (
    <>
      {screenWidth > 780 ? (
        <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
          <div
            className="OfficialContainer"
            style={{
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: 'rgba(248, 248, 248, 1)',
            }}
          >
            <HeadBarPC />

            <div
              style={{
                transformOrigin: 'top',
                scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}`,
                width: '1224px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
                top: '0',
              }}
            >
              <div style={{ width: '100%', marginTop: '200px' }}></div>

              <div style={{ width: '100%', marginBottom: '32px' }}>
                <p
                  style={{
                    fontSize: '24px',
                    color: 'black',
                    fontWeight: '500',
                  }}
                >
                  我的参赛信息
                </p>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    flex: '50%',
                    borderRadius: '12px',
                    backgroundColor: 'white',
                    paddingTop: '80px',
                    paddingBottom: '80px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: committed ? '' : 'center',
                    padding: committed ? '32px' : '80px 0',
                  }}
                >
                  {info.leader_name && (
                    <>
                      {committed && (
                        <>
                          <div
                            style={{
                              width: '340px',
                              height: '40px',
                              display: 'flex',
                              alignItems: 'center',
                              fontSize: '17px',
                              fontWeight: '500',
                            }}
                          >
                            报名信息
                          </div>
                          <div style={{ height: '24px' }}></div>
                        </>
                      )}
                      <div
                        style={{
                          width: '340px',
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}
                      >
                        参赛成员信息
                      </div>
                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>
                      {renderResultItem(
                        '报名账号',
                        userManager.account ? userManager.account : '',
                      )}
                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>
                      {renderResultItem('姓名', info.leader_name)}

                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>
                      {renderResultItem('所属学校', info.leader_school_name)}

                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>
                      {renderResultItem('身份证号', info.leader_IDCard)}
                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>
                      {renderResultItem('手机', info.leader_mobile)}
                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>

                      {renderMemberList()}
                      <div
                        style={{
                          width: '340px',
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}
                      >
                        参赛团队信息
                      </div>

                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>
                      {renderResultItem('分组', info.entry_group)}

                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>

                      {/* <div style={{ width: '340px', height: '40px', display: 'flex', alignItems: 'center', fontSize: '14px', fontWeight: '600' }}>指导老师信息</div>
                                            <div style={{ height: committed ? '12px' : '24px' }}></div> */}
                      {renderResultItem(
                        '指导老师姓名',
                        info.guide_teacher_name,
                      )}

                      <div
                        style={{ height: committed ? '12px' : '24px' }}
                      ></div>
                      {renderResultItem(
                        '指导老师联系方式',
                        info.guide_teacher_mobile,
                      )}
                      <div style={{ height: committed ? '' : '80px' }}></div>
                    </>
                  )}
                </div>

                {committed && (
                  <>
                    <div style={{ width: '48px' }}></div>
                    <div style={{ flex: '50%' }}>
                      <div
                        style={{
                          borderRadius: '12px',
                          backgroundColor: 'white',
                          padding: '32px',
                          height: 'auto',
                          boxSizing: 'border-box',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '17px',
                            fontWeight: '500',
                            justifyContent: 'space-between',
                          }}
                        >
                          作品信息
                          <div
                            onClick={() => {
                              navigate('/commit', {
                                state: { info: 'modify' },
                              });
                            }}
                            style={{
                              height: '20px',
                              fontSize: '14px',
                              color: 'rgba(0,0,0,0.55)',
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            {/* <p>8月31日前可修改</p> */}
                            <div
                              style={{
                                backgroundImage: `url(${preURL}/svg/modify.svg)`,
                                height: '16px',
                                width: '16px',
                                marginLeft: '4px',
                              }}
                            ></div>
                          </div>
                        </div>
                        <div style={{ height: '24px' }}></div>

                        {renderResultItem('参赛命题', info.proposition)}
                        <div
                          style={{ height: committed ? '12px' : '24px' }}
                        ></div>
                        {renderResultItem('作品名称', info.entry_name)}
                        <div
                          style={{ height: committed ? '12px' : '24px' }}
                        ></div>
                        {renderResultItem('AppID', info.appid)}
                        <div
                          style={{ height: committed ? '12px' : '24px' }}
                        ></div>
                        {renderResultItem('代码包', info.submit_version)}
                        <div
                          style={{ height: committed ? '12px' : '24px' }}
                        ></div>
                        {renderResultItem('说明文档', info.attachment_doc_name)}
                        <div
                          style={{ height: committed ? '12px' : '24px' }}
                        ></div>
                        {renderResultItem(
                          '讲解视频',
                          info.attachment_video_name,
                        )}
                        <div
                          style={{ height: committed ? '12px' : '24px' }}
                        ></div>
                        {renderResultItem(
                          '提交时间',
                          formatTimestamp(info.submit_time),
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div style={{ marginBottom: '50px', marginTop: '100px' }}>
                <CopyRightPC />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ParticipationInfoMobile
          info={info}
          renderResultItem={renderResultItem}
          renderMemberList={renderMemberList}
        />
      )}
    </>
  );
};

export default ParticipationInfo;
