import CryptoJS from 'crypto-js';

export function getOperatingSystem() {
    const platform = navigator.platform.toLowerCase();
    const userAgent = navigator.userAgent.toLowerCase();

    if (platform.includes('win') || userAgent.includes('windows')) {
        return 'Windows';
    } else if (platform.includes('mac') || userAgent.includes('macintosh') || userAgent.includes('mac os')) {
        return 'Mac';
    } else {
        return 'Unknown';
    }
}


export enum ApplicationType {
    darwin64 = 'darwin_x64',
    darwinArm = 'darwin_arm64',
    win64 = 'win32_x64',
    win32 = 'win32_ia32',
}

export const PLATFORM_TO_NAME = {
    [ApplicationType.darwin64]: 'Mac Intel芯片版',
    [ApplicationType.darwinArm]: 'Mac Apple芯片版',
    [ApplicationType.win32]: 'Windows32版',
    [ApplicationType.win64]: 'Windows64版',
}

export const PLATFORM_TO_NAME_MD = {
    [ApplicationType.darwin64]: 'Mac Intel 芯片版',
    [ApplicationType.darwinArm]: 'Mac Apple 芯片版',
    [ApplicationType.win32]: 'Windows 32 版',
    [ApplicationType.win64]: 'Windows 64 版',
}


export function getDownloadFileName(strVersion: string, applicationType: ApplicationType) {
    let isMac = false
    if (applicationType === ApplicationType.darwin64 || applicationType === ApplicationType.darwinArm) {
        isMac = true
    }
    const exeType = isMac ? 'dmg' : 'exe'
    return `wechat_devtools_${strVersion}_${applicationType}.${exeType}`
}


export function formatTimestamp(timestamp: number) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}`;
}


export function sha256(msg: string) {
    // return msg
    const hash = CryptoJS.SHA256(msg);

    return hash.toString(CryptoJS.enc.Hex)
}

export function getQueryParams(queryString: string) {
    const params: Record<string, string> = {};
    const paris = queryString.split("&");

    paris.forEach(pair=>{
      const [key,value] = pair.split("=");
      if(key){
        params[decodeURIComponent(key)] = decodeURIComponent(value || '');
      }
    })

    return params;
}

export function debounce(func: Function, wait: number) {
    let timeout: NodeJS.Timeout;
    return function (this: any, ...args: any[]) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

