import React, { memo } from 'react';
import { useDeviceStore } from '../store';

const MultiEndedHoc = (
  DesktopComponent: React.ComponentType,
  MobileComponent: React.ComponentType,
) => {
  return memo(() => {
    const { screenWidth } = useDeviceStore((state) => state);
    return (
      <div>
        {screenWidth > 780 ? <DesktopComponent /> : <MobileComponent />}
      </div>
    );
  });
};

export default MultiEndedHoc;
