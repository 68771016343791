import courseList from '../../../config/courseList';
import { useIntl } from 'react-intl';
import '../OfficialTextBlock.css';
import { EduSvgCollection } from '../../SvgManager';

const OfficialTextBlockDesktop = () => {
  const intl = useIntl();

  return (
    <>
      {courseList.map((courseData, courseListIndex) => {
        const courseGroup = courseData.courseGroup || [];
        const GroupTitle = (props: any) => {
          const { titleIntlObj } = props;
          return (
            <div style={{ width: '100%', display: 'flex', height: '34px' }}>
              <div
                style={{
                  width: '6px',
                  height: '6px',
                  backgroundColor: 'rgba(16,174,255,1)',
                  marginRight: '6px',
                }}
              ></div>
              <div
                style={{
                  fontSize: '24px',
                  fontWeight: '500',
                  marginRight: '6px',
                }}
              >
                {intl.formatMessage(titleIntlObj)}
              </div>
              <div
                style={{
                  width: '8px',
                  height: '8px',
                  backgroundColor: 'rgba(7,193,96,1)',
                  alignSelf: 'end',
                }}
              ></div>
            </div>
          );
        };

        return (
          <div
            key={intl.formatMessage(courseData.title)}
            style={{ width: '100%' }}
          >
            <GroupTitle titleIntlObj={courseData.title} />
            <div className="official-text-block__group">
              {courseGroup.map((courseGroupData, groupIndex) => {
                const courseList = courseGroupData.list || [];
                return (
                  <div
                    key={intl.formatMessage(courseGroupData.title)}
                    className="official-text-block__course"
                    style={{ border: courseGroupData.border }}
                  >
                    {/* 组内标题 */}
                    <div className="official-text-block__course-header">
                      <div className="official-text-block__course-type">
                        {courseGroupData.type}
                      </div>
                      <div className="official-text-block__course-title">
                        {intl.formatMessage(courseGroupData.title)}
                      </div>
                    </div>
                    {/* 组内内容 */}
                    <div className="official-text-block__course-content">
                      {courseList.map((courseData, courseIndex) => {
                        const courseId = `${courseListIndex}-${groupIndex}-${courseIndex}}`;
                        return (
                          <div
                            key={courseId}
                            onClick={() => {
                              window.open(courseData.url, '_blank');
                            }}
                            className="official-text-block__course-item"
                          >
                            <div
                              style={{
                                fontSize: '17px',
                                color: 'rgba(0,0,0,0.9)',
                              }}
                            >
                              {courseData.text}
                            </div>
                            <EduSvgCollection.PlayVideo className="official-text-block__course-item-play" />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OfficialTextBlockDesktop;
