import { FormattedMessage, useIntl } from 'react-intl';
import { EduSvgCollection } from '../SvgManager';
// import { Player } from "video-react";

const VideoCard = () => {
  const preURL = process.env.PUBLIC_URL;
  const intl = useIntl();

  const TextItem = ({ text }: { text: string }) => {
    return (
      <div className="official-video-card__textitem">
        <EduSvgCollection.Light />
        <div className="official-video-card__textitem-content">{text}</div>
      </div>
    );
  };

  return (
    <div className="official-video-card">
      <div className="official-video-card__leftbox">
        <div className="official-video-card__title">
          <FormattedMessage
            id="Official.solveProblemTitle"
            description="看看同学们如何自己开发小程序，解决生活中的问题。"
            defaultMessage="看看同学们如何自己开发小程序，解决生活中的问题。"
          />
        </div>
        <div style={{ marginTop: '24px' }}>
          <TextItem
            text={intl.formatMessage({
              id: 'Official.problem2',
              description: '食物浪费怎么办',
              defaultMessage: '食物浪费怎么办',
            })}
          />
          <TextItem
            text={intl.formatMessage({
              id: 'Official.problem3',
              description: '校运会成绩在哪看',
              defaultMessage: '校运会成绩在哪看',
            })}
          />
          <TextItem
            text={intl.formatMessage({
              id: 'Official.problem1',
              description: '花园如何科学浇水',
              defaultMessage: '花园如何科学浇水',
            })}
          />
        </div>
      </div>
      <div style={{ width: '660px' }}>
        <video
          id="video"
          src={`${preURL}/img/video.mov`}
          style={{ width: '100%' }}
          autoPlay
          loop
          playsInline
          controls
          muted
        ></video>
        {/* <Player
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          loop
          autoPlay
          muted
          playsInline
          src={`${preURL}/img/video.mov`}
        /> */}
      </div>
    </div>
  );
};

export default VideoCard;
