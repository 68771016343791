import axios from 'axios';
import { isDev } from './env';

export enum ANSWER_USEFUL {
    UNSET = 0,
    USEFUL = 1,
    NOT_USEFUL = 2
}

export const LIKE_STATUS = {
    LIKE: 1,
    DISLIKE: 2
}

export enum REPORT_TYPE {
    ASK = "ASK",
    FEEDBACK = "FEEDBACK"
}
export interface IReportItem {
    biz_id?: number;
    time?: number;

    method?: string;
    appid?: string;
    user_id?: string;
    info?: string;
    platform?: 'PC' | 'mobile'
}
export const REPORT_ENUM = {
    STU_SIGNUP: "10010",
    TEACHER_SIGNUP: "10011"
}

export async function sendLog(reportItem: IReportItem) {
    // 本地开发模式不上报日志
    if(isDev()) {
        return;
    }
    reportItem.biz_id = 12726
    reportItem.time = Math.ceil(Date.now() / 1000)
    const body = 'report_items=' + JSON.stringify([reportItem]).replace(/&/g, '%26');
    return axios({
        method: 'post',
        url: 'https://cube.weixinbridge.com/cube/report/reportbizdata?f=json',
        data: body,
    });
}

