import { useEffect } from 'react';
import { Button } from './Dialog/RegistDialog';

interface IMobilePopupProps {
  word: string;
  cancelWord: string;
  confirmWord: string;
  confirmAction: () => void;
  cancelAction: () => void;
}

export function MobilePopup(props: IMobilePopupProps) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const { word, cancelWord, confirmAction, confirmWord, cancelAction } = props;
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '100',
      }}
    >
      <div
        style={{
          width: '100%',
          margin: '0 16px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            alignSelf: 'self-start',
            fontSize: '20px',
            fontWeight: '500',
            color: 'rgba(0,0,0,0.9)',
          }}
        >
          {word}
        </div>
        <div style={{ height: '32px' }}></div>
        <div style={{ alignSelf: 'self-end', display: 'flex' }}>
          <Button
            text={cancelWord}
            onClick={() => {
              if (cancelAction) {
                cancelAction();
              }
            }}
          ></Button>
          <div style={{ width: '12px' }}></div>
          <Button
            text={confirmWord}
            backgroundColor="rgba(7,193,96,1)"
            color="white"
            onClick={() => {
              if (confirmAction) {
                confirmAction();
              }
            }}
          ></Button>
        </div>
      </div>
    </div>
  );
}
