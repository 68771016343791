import React, { useEffect } from 'react';
import { Button } from './Dialog/RegistDialog';

interface ICommonPopupProps {
  cancelWord: string;
  confirmAction: () => void;
  confirmWord: string;
  cancelAction: () => void;
  child: any;
  width?: number;
}

export const CommonPopup = (props: ICommonPopupProps) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const { cancelWord, confirmAction, confirmWord, cancelAction, child, width } =
    props;
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '100',
      }}
    >
      <div
        style={{
          width: width ? `${width}px` : '400px',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '28px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div></div>
          <svg
            onClick={() => {
              cancelAction();
            }}
            style={{ cursor: 'pointer' }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.9987 13.0605L5.55857 19.4994L4.49801 18.4386L10.9379 11.9999L4.49707 5.56024L5.55763 4.49948L11.9987 10.9394L18.4423 4.49695L19.5029 5.55771L13.0594 11.9999L19.5029 18.4423L18.4424 19.503L11.9987 13.0605Z"
              fill="rgba(0,0,0,0.3)"
            />
          </svg>
        </div>
        {child}
        <div style={{ height: '32px' }}></div>
        <div style={{ alignSelf: 'self-end', display: 'flex' }}>
          <Button
            text={cancelWord}
            onClick={() => {
              if (cancelAction) {
                cancelAction();
              }
            }}
          ></Button>
          <div style={{ width: '12px' }}></div>
          <Button
            text={confirmWord}
            backgroundColor="rgba(7,193,96,1)"
            color="white"
            onClick={() => {
              if (confirmAction) {
                confirmAction();
              }
            }}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default CommonPopup;
