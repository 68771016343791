import MultiEndedHoc from '../MultiEndedHoc';
import OfficialTextBlockDesktop from './desktop/OfficialTextBlock';
import OfficialTextBlockMobile from './mobile/OfficialTextBlock';

const OfficialTextBlock = () => {
  const WrappedComponent = MultiEndedHoc(
    OfficialTextBlockDesktop,
    OfficialTextBlockMobile,
  );
  return <WrappedComponent />;
};

export default OfficialTextBlock;
