import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { UserStore, UserType, useDeviceStore, useUserStore } from '../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  RadioInputBox,
  Input,
  RadioInputBoxRef,
} from '../../components/Dialog/RegistDialog';
import { CopyRightPC } from '../login/mobile/Login';
import CommonPopup from '../../components/commonPopup';
import { HeadBarPC } from '../../components/HeadBar/HeadBarPC';
import axios, { CancelTokenSource } from 'axios';
import urlConfigs from '../../config/urlList';

export interface ICommitForm {
  proposition: string;
  title: string;
  AppID: string;
  codePack: string;
  document: string;
  video: string;
  documentName: string;
  videoName: string;
}
const preURL = process.env.PUBLIC_URL;
const propositionOptions = [
  '智慧校园',
  '智能生活',
  '社会焦点',
  '我为地球做件事',
  '解锁未来',
];
const Commit = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;
  const info = state?.info;

  const { screenWidth, setScreenWidth } = useDeviceStore();
  const [step, setStep] = useState(1);
  const userManager = useUserStore.getState() as UserStore;
  const [showCheckPcPopup, setShowCheckPcPopup] = useState(false);
  const [appidOptions, setAppidOptions] = useState<any[]>([]);
  const [codePkgOptions, setCodePkgOptions] = useState<any[]>([]);
  const radioInputBoxRef = useRef<RadioInputBoxRef>(null);
  const [form, setForm] = useState<ICommitForm>({
    proposition: '',
    title: '',
    AppID: '',
    codePack: '',
    document: '',
    video: '',
    videoName: '',
    documentName: '',
  });

  const [formErrMsg, setFormErrMsg] = useState<ICommitForm>({
    proposition: '',
    title: '',
    AppID: '',
    codePack: '',
    document: '',
    video: '',
    videoName: '',
    documentName: '',
  });

  const nextStep = async () => {
    console.log(form, formErrMsg);
    const newFormErrMsg = {
      proposition: '',
      title: '',
      AppID: '',
      codePack: '',
      document: '',
      video: '',
      videoName: '',
      documentName: '',
    };
    if (form.proposition === '') {
      newFormErrMsg.proposition = '请选择参赛命题';
    }
    if (form.title === '') {
      newFormErrMsg.title = '请填写作品名称';
    }
    if (form.AppID === '') {
      newFormErrMsg.AppID = '请选择AppId';
    }
    if (form.codePack === '') {
      newFormErrMsg.codePack = '请选择代码包';
    }
    if (form.document === '') {
      newFormErrMsg.document = '请上传说明文档';
    }
    // if (form.video === '') {
    //     newFormErrMsg.video = '请上传讲解视频'
    // }

    setFormErrMsg(newFormErrMsg);
    const newFormErrMsgEmpty = Object.values(newFormErrMsg).every(
      (value) => value === '',
    );

    if (newFormErrMsgEmpty) {
      setStep(2);
    }
  };

  useEffect(() => {
    if (info === 'modify') {
      getPerticipationInfo();
    }
  }, [info]);

  const getPerticipationInfo = async () => {
    const data = {
      competition_uuid: '20240620',
    };
    const url = `${urlConfigs.edudev_getparticipationinfo}?eduticket=${userManager.ticket}`;

    try {
      const resp = await axios.post(url, JSON.stringify(data));

      if (resp.data.errcode === 0 && resp.data.participation_info) {
        setForm({
          proposition: resp.data.participation_info.proposition,
          title: resp.data.participation_info.entry_name,
          AppID: resp.data.participation_info.appid,
          codePack: resp.data.participation_info.submit_version,
          document: resp.data.participation_info.attachment_doc_cosid,
          video: resp.data.participation_info.attachment_video_cosid,
          videoName: resp.data.participation_info.attachment_video_name,
          documentName: resp.data.participation_info.attachment_doc_name,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  async function commit() {
    const url = `${urlConfigs.selectappversionparticipate}?eduticket=${userManager.ticket}`;
    // 提交
    const data = {
      competition_uuid: '20240620', //赛事uuid
      entry_name: form.title, //作品名称
      appid: form.AppID, //appid
      entry_group: form.proposition, //参赛分组
      entry_desc: 'no desc', //作品描述
      attachment_video_cosid: form.video, //视频资源id
      attachment_doc_cosid: form.document, //文档资源id
      attachment_video_name: form.videoName, //视频资源名称
      attachment_doc_name: form.documentName, //文档资源名称
      user_version: form.codePack, //版本号
    };
    const resp = await axios.post(url, JSON.stringify(data));
    if (resp.data.errcode === 0) {
      setStep(3);
    } else {
      setStep(4);
    }
    // 成功，跳成功step
    // 失败，跳失败step
  }

  function handleInputChange(item: string, val: string): void {
    if (form?.[item as keyof ICommitForm] === undefined) return;
    setForm((prev) => ({
      ...prev,
      [item]: val,
    }));
  }

  const getAppidList = async () => {
    if (userManager.accountType === UserType.Student) {
      const resp = await axios.post(
        urlConfigs.get_student_appinfo,
        JSON.stringify({ ticket: userManager.ticket }),
      );
      console.log(resp);
      if (resp.data.app_list) {
        setAppidOptions(resp.data.app_list);
      }
    } else {
      const resp = await axios.post(
        urlConfigs.get_school_appdetail,
        JSON.stringify({ ticket: userManager.ticket }),
      );
      console.log(resp);
      if (resp.data.app_list) {
        setAppidOptions(resp.data.app_list);
      }
    }
  };

  const getCodePkgList = async (appid: string) => {
    const resp = await axios.post(
      urlConfigs.get_app_commitinfo,
      JSON.stringify({ ticket: userManager.ticket, appid }),
    );
    if (resp.data.commit_list) {
      console.log(resp.data.commit_list);
      setCodePkgOptions(resp.data.commit_list);
    }
  };

  useEffect(() => {
    getAppidList();
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = 'rgba(248, 248, 248, 1)';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const renderAppidList = () => {
    return appidOptions.map((item) => {
      return (
        <div
          className="select-appid-item"
          key={item.appid}
          onClick={() => {
            getCodePkgList(item.appid);
            handleInputChange('AppID', item.appid);
            if (radioInputBoxRef.current) {
              radioInputBoxRef.current.blur();
            }
          }}
        >
          <div className="select-appid-item-appid">{item.appid}</div>
          <div className="select-appid-item-nick-name">{item.nick_name}</div>
        </div>
      );
    });
  };
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderCodePkg = () => {
    if (codePkgOptions.length == 0) {
      return (
        <div
          // className='select-appid-item'
          style={{
            height: '104px',
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          暂无数据，可前往开发者工具上传最新代码
        </div>
      );
    }
    return codePkgOptions.map((item) => {
      return (
        <div
          className="select-appid-item"
          style={{ height: '36px' }}
          key={item.originl_md5}
          onClick={() => {
            handleInputChange('codePack', item.user_version);
            if (radioInputBoxRef.current) {
              radioInputBoxRef.current.blur();
            }
          }}
        >
          <div className="select-appid-item-appid">{item.user_version}</div>
        </div>
      );
    });
  };

  const renderResultItem = (k: string, v: string) => {
    return (
      <div
        style={{
          width: '340px',
          display: 'flex',
          alignItems: 'top',
          minHeight: '40px',
        }}
      >
        <div
          style={{
            flex: '0 0 124px',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.55)',
          }}
        >
          {k}
        </div>
        <div
          style={{
            color: 'rgba(0,0,0,0.9)',
            fontSize: '14px',
            fontWeight: '400',
            maxWidth: '236px',
          }}
        >
          {v}
        </div>
      </div>
    );
  };
  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
      <div
        className="OfficialContainer"
        style={{
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'rgba(248, 248, 248, 1)',
        }}
      >
        <HeadBarPC />

        <div
          style={{
            transformOrigin: 'top',
            scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}`,
            width: '1224px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            top: '0',
            position: 'absolute',
          }}
        >
          <div style={{ width: '100%', marginTop: '200px' }}></div>

          <div style={{ width: '100%', marginBottom: '32px' }}>
            <p style={{ fontSize: '24px', color: 'black', fontWeight: '500' }}>
              提交作品
            </p>
          </div>

          <div
            style={{
              width: '100%',
              /*height: '1268px',*/ borderRadius: '12px',
              backgroundColor: 'white',
              paddingTop: '80px',
              paddingBottom: '80px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {(step === 1 || step === 2) && (
              <>
                <div
                  style={{
                    height: '24',
                    width: '340px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor:
                          step === 1 ? 'rgba(7, 193, 96, 1)' : '',
                        border:
                          step === 1 ? '' : '0.5px solid rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          color: step === 1 ? 'white' : 'rgba(0, 0, 0, 0.3)',
                        }}
                      >
                        1
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: '14px',
                        marginLeft: '8px',
                        color: step === 1 ? 'rgba(7, 193, 96, 1)' : '',
                        fontWeight: '500',
                      }}
                    >
                      作品信息
                    </p>
                  </div>

                  <div style={{ color: 'rgba(0, 0, 0, 0.1)' }}>
                    ············
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor:
                          step === 2 ? 'rgba(7, 193, 96, 1)' : '',
                        border:
                          step === 2 ? '' : '0.5px solid rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          color: step === 2 ? 'white' : 'rgba(0, 0, 0, 0.3)',
                        }}
                      >
                        2
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: '14px',
                        marginLeft: '8px',
                        color: step === 2 ? 'rgba(7, 193, 96, 1)' : '',
                        fontWeight: '500',
                      }}
                    >
                      检查确认
                    </p>
                  </div>
                </div>

                <div style={{ height: '24px' }}></div>
                <div style={{ height: '40px' }}></div>
                <div style={{ height: '24px' }}></div>
              </>
            )}

            {step === 1 && (
              <>
                <div style={{ width: '488px' }}>
                  <RadioInputBox
                    options={propositionOptions}
                    errMsg={formErrMsg.proposition}
                    desc="参赛命题"
                    descWidth="124"
                    placeHolder="选择"
                    value={form.proposition}
                    onChange={(item: string) =>
                      handleInputChange('proposition', item)
                    }
                  />
                </div>
                <div style={{ height: '24px' }}></div>
                <div style={{ width: '488px' }}>
                  <Input
                    errMsg={formErrMsg.title}
                    desc="作品名称"
                    descWidth="124"
                    placeHolder="填写"
                    value={form.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                  />
                </div>
                <div style={{ height: '24px' }}></div>
                <div style={{ width: '488px' }}>
                  <RadioInputBox
                    ref={radioInputBoxRef}
                    optsElements={renderAppidList()}
                    errMsg={formErrMsg.AppID}
                    desc="AppID"
                    descWidth="124"
                    placeHolder="选择"
                    value={form.AppID}
                    onChange={(item: string) => {
                      handleInputChange('AppID', item);
                      getCodePkgList(item);
                    }}
                  />
                </div>
                <div style={{ height: '24px' }}></div>
                <div style={{ width: '488px' }}>
                  <RadioInputBox
                    ref={radioInputBoxRef}
                    optsElements={renderCodePkg()}
                    errMsg={formErrMsg.codePack}
                    desc="代码包"
                    descWidth="124"
                    placeHolder="选择"
                    value={form.codePack}
                    onChange={(item: string) =>
                      handleInputChange('codePack', item)
                    }
                  />
                </div>
                <div style={{ height: '24px' }}></div>

                <UploadInput
                  title="说明文档"
                  accessTypes={['pdf']}
                  desc="必须上传，可展现创作背景、创作思路、实践过程等内容。大小不超过100MB。（支持PDF文件）"
                  onSuccess={({ id, name }: { id: string; name: string }) => {
                    console.log(id, name);
                    handleInputChange('document', id);
                    handleInputChange('documentName', name);
                  }}
                  errMsg={formErrMsg.document}
                  type="doc"
                  uploadedFile={form.documentName}
                />
                <div style={{ height: '24px' }}></div>

                <UploadInput
                  title="讲解视频"
                  accessTypes={['mov', 'mp4']}
                  desc="选择上传，可讲解作品制作过程和使用操作等内容。大小不超过300MB。（支持MP4、MOV文件）"
                  onSuccess={({ id, name }: { id: string; name: string }) => {
                    console.log(id, name);
                    handleInputChange('video', id);
                    handleInputChange('videoName', name);
                  }}
                  errMsg={formErrMsg.video}
                  type="video"
                  uploadedFile={form.videoName}
                />

                <div style={{ height: '80px' }}></div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    text="取消"
                    // onClick={() => { console.log(form) }}
                    onClick={() => {
                      navigate('/eventDetails');
                    }}
                  />
                  <div style={{ width: '12px' }}></div>
                  <Button
                    text="预览"
                    color="white"
                    backgroundColor="rgba(7, 193, 96, 1)"
                    onClick={async () => {
                      await nextStep();
                    }}
                  />
                </div>
              </>
            )}

            {step === 2 && (
              <>
                {renderResultItem('参赛命题', form.proposition)}
                <div style={{ height: '24px' }}></div>
                {renderResultItem('作品名称', form.title)}

                <div style={{ height: '24px' }}></div>
                {renderResultItem('AppID', form.AppID)}

                <div style={{ height: '24px' }}></div>
                {renderResultItem('代码包', form.codePack)}
                <div style={{ height: '24px' }}></div>
                {renderResultItem('说明文档', form.documentName)}
                <div style={{ height: '24px' }}></div>
                {renderResultItem('讲解视频', form.videoName)}

                <div style={{ height: '80px' }}></div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    text="返回"
                    onClick={() => {
                      setStep(1);
                    }}
                  />
                  <div style={{ width: '12px' }}></div>
                  <Button
                    text="提交"
                    color="white"
                    backgroundColor="rgba(7, 193, 96, 1)"
                    onClick={async () => {
                      commit();
                    }}
                  />
                </div>
              </>
            )}

            {step === 3 && (
              <>
                <div style={{ height: '116px' }}></div>
                <div
                  style={{
                    backgroundImage: `url(${preURL}/svg/check-circle2.svg)`,
                    width: '60px',
                    height: '60px',
                  }}
                ></div>
                <div style={{ height: '32px' }}></div>
                <p style={{ fontSize: '24px', fontWeight: '500' }}>已提交</p>
                {/* <div style={{ height: '8px' }}></div>
                                <p style={{ fontSize: '14', color: 'rgba(0, 0, 0, 0.3)' }}>可在提交截止日期8月31日前修改</p> */}
                <div style={{ height: '68px' }}></div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    text="返回赛事页"
                    width={137}
                    onClick={() => {
                      navigate('/eventDetails');
                    }}
                  />
                </div>
                <div style={{ height: '116px' }}></div>
              </>
            )}

            {step === 4 && (
              <>
                <div style={{ height: '116px' }}></div>
                <div
                  style={{
                    backgroundImage: `url(${preURL}/svg/info-circle.svg)`,
                    width: '60px',
                    height: '60px',
                  }}
                ></div>
                <div style={{ height: '32px' }}></div>
                <p style={{ fontSize: '24px', fontWeight: '500' }}>提交失败</p>
                <div style={{ height: '8px' }}></div>
                <p style={{ fontSize: '14', color: 'rgba(0, 0, 0, 0.3)' }}>
                  可尝试重新提交
                </p>
                <div style={{ height: '68px' }}></div>

                <Button
                  text="返回赛事页"
                  width={137}
                  onClick={() => {
                    navigate('/eventDetails');
                  }}
                />
                <div style={{ height: '116px' }}></div>
              </>
            )}
          </div>

          <div
            style={{
              fontSize: '14px',
              fontWeight: '500',
              color: 'rgba(0,0,0,0.3)',
              marginBottom: '50px',
              marginTop: '100px',
            }}
          >
            <CopyRightPC />
          </div>
        </div>

        {showCheckPcPopup && (
          <CommonPopup
            width={488}
            child={
              <div
                style={{
                  marginTop: '56px',
                  marginBottom: '64px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontSize: '20px',
                  color: 'rgba(0,0,0,0.9)',
                }}
              >
                <p>提交后，报名信息将无法修改，</p>
                <p>是否确认提交？</p>
              </div>
            }
            cancelAction={() => {
              setShowCheckPcPopup(false);
            }}
            cancelWord="取消"
            confirmAction={() => {
              // confirmForm()
              setShowCheckPcPopup(false);
            }}
            confirmWord="提交"
          />
        )}
      </div>
    </div>
  );
};

export default Commit;

interface IUploadInputProps {
  title: string;
  desc: string;
  onSuccess: ({ id, name }: { id: string; name: string }) => void;
  type: 'video' | 'doc';
  errMsg?: string;
  accessTypes: string[];
  uploadedFile?: string;
}

const UploadInput = (props: IUploadInputProps) => {
  // console.log(props)
  const { title, desc, onSuccess, type, accessTypes } = props;
  const userManager = useUserStore.getState() as UserStore;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (props.errMsg !== undefined) {
      setErrMsg(props.errMsg);
    }
    if (props.uploadedFile) {
      setFileName(props.uploadedFile);
    }
  }, [props.errMsg, props.uploadedFile]);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    let file;

    if (event.target.files && event.target.files.length > 0) {
      file = event.target.files[0];
      setFileName(file.name);
    }
    if (!file) {
      return;
    }

    // @ts-ignore
    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (!accessTypes.includes(fileExtension)) {
      setErrMsg('上传失败，请检查文件类型');
      return;
    }

    setUploading(true);
    cancelTokenSource.current = axios.CancelToken.source();

    try {
      const arrayBuffer = await file.arrayBuffer();
      const binaryData = new Uint8Array(arrayBuffer);

      const resp = await axios.post(
        `${urlConfigs.uploadparticipateattachment}?eduticket=${userManager.ticket}&type=${type}`,
        binaryData,
        {
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              if (progressEvent.total) {
                const percentComplete =
                  (progressEvent.loaded / progressEvent.total) * 100;
                setUploadProgress(percentComplete);
                console.log(`Uploaded ${percentComplete}%`);
              } else {
                console.log(`totol is not define`);
              }
            }
          },
          cancelToken: cancelTokenSource.current.token,
        },
      );

      setUploading(false);

      if (resp.status === 200) {
        console.log('Upload complete!');
        setUploaded(true);
        setUploadProgress(0);
        if (onSuccess) {
          onSuccess({ id: resp.data.id, name: file.name });
        }
        setErrMsg('');
      } else {
        setErrMsg('上传失败，请稍后再试');
        console.error('Upload failed!');
      }

      if (resp.data.errcode !== 0) {
        setErrMsg(`上传失败，${resp.data.errcode}`);
      }
    } catch (error) {
      console.error('Upload error!', error);
    }
  };

  const handleCancelUpload = () => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('Upload canceled by user');
      setUploading(false);
    }
  };

  return (
    <div style={{ display: 'flex', width: '488px' }}>
      <div
        style={{
          width: '124px',
          flexShrink: '0',
          fontSize: '14px',
          color: 'rgba(0,0,0,0.55)',
        }}
      >
        {title}
      </div>
      <div style={{ width: '100%' }}>
        <p style={{ fontSize: '14px', color: 'rgba(0,0,0,0.3)' }}>{desc}</p>
        <div style={{ height: '12px' }}></div>
        <input
          type="file"
          onChange={handleUpload}
          ref={fileInputRef}
          style={{ display: 'none' }}
        />

        {uploading ? (
          <div
            style={{
              height: '20px',
              width: '272px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '240px',
                height: '4px',
                borderRadius: '20px',
                backgroundColor: 'rgba(227,228,229,1)',
              }}
            >
              <div
                style={{
                  height: '4px',
                  width: `${uploadProgress}%`,
                  backgroundColor: 'rgba(7,193,96,1)',
                  borderRadius: '20px',
                }}
              ></div>
            </div>
            <div
              onClick={handleCancelUpload}
              style={{
                height: '20px',
                width: '20px',
                borderRadius: '50%',
                backgroundImage: `url(${preURL}/svg/x-circle.svg)`,
                cursor: 'pointer',
              }}
            ></div>
          </div>
        ) : (
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <div>
              {errMsg?.length !== 0 ? (
                <div
                  style={{
                    color: 'rgba(250,81,81,1)',
                    fontSize: '14px',
                    lineHeight: '20px',
                    marginRight: '12px',
                    maxWidth: '290px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {errMsg}
                </div>
              ) : (
                <>
                  {fileName.length > 0 && (
                    <div
                      style={{
                        color: 'rgba(0,0,0,0.9)',
                        fontSize: '14px',
                        lineHeight: '20px',
                        marginRight: '12px',
                        maxWidth: '290px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {fileName}
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              style={{ display: 'flex', cursor: 'pointer' }}
              onClick={() => {
                if (fileInputRef.current) {
                  fileInputRef.current.click();
                }
              }}
            >
              {uploaded || props.uploadedFile ? (
                <>
                  <span
                    style={{
                      fontSize: '14px',
                      color: 'rgba(87,107,149,1)',
                      lineHeight: '20px',
                    }}
                  >
                    重新上传
                  </span>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundImage: `url(${preURL}/svg/plus2.svg)`,
                    }}
                  ></div>
                  <div style={{ width: '4px' }}></div>
                  <span
                    style={{
                      fontSize: '14px',
                      color: 'rgba(87,107,149,1)',
                      lineHeight: '20px',
                    }}
                  >
                    上传文件
                  </span>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
