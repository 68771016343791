import React, { useState } from 'react';
import { HeadBarMobile } from '../../../components/HeadBarMobile';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../store';
import axios from 'axios';
import urlConfigs from '../../../config/urlList';
import { Button, Input } from '../../../components/Dialog/RegistDialog';
import { sha256 } from '../../../config/utils';
import { FormattedMessage, useIntl } from 'react-intl';

const LoginMobile = () => {
  const navigate = useNavigate();

  return (
    <div
      className="CompetitionContainer"
      style={{
        width: '100%',
        backgroundColor: 'rgb(248,248,248)',
        position: 'relative',
        height: '100vh',
      }}
    >
      <HeadBarMobile
        word="双城青少年编程创新赛"
        leftClick={() => {
          navigate('/eventDetails');
        }}
        rightAction=""
        rightClick={() => {}}
      />
      <div style={{ height: '40px' }}></div>
      <LoginForm />

      <div style={{ position: 'absolute', bottom: '40px', width: '100%' }}>
        <CopyRightMobile />
      </div>
    </div>
  );
};

export default LoginMobile;

export function LoginForm() {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');

  const [accountErrMsg, setAccountErrMsg] = useState('');
  const [pwdErrMsg, setPwdErrMsg] = useState('');
  const navigate = useNavigate();

  const intl = useIntl();

  const handleLogin = async () => {
    setAccountErrMsg('');
    setPwdErrMsg('');

    const data = {
      account_name: account,
      account_pwd: sha256(password),
    };
    const resp = await axios.post(
      urlConfigs.loginbyaccount,
      JSON.stringify(data),
    );
    if (resp.data.errcode === 0) {
      // 存到store里面
      login(
        resp.data.eduticket,
        resp.data.account_name,
        resp.data.account_type,
        Date.now() + resp.data.eduticket_expire_time * 1000,
      );
      // 跳转到eventDetails页面
      navigate('/eventDetails');
    } else if (resp.data.errcode === 100135) {
      //账号不对
      setAccountErrMsg('账号不存在');
    } else if (resp.data.errcode === 100136) {
      // 授权码不对
      setPwdErrMsg('密码错误');
    }
  };
  return (
    <div style={{ padding: '0 18px' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '12px',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <p style={{ fontSize: '20px', fontWeight: '500' }}>
            <FormattedMessage
              id="login.login"
              description="登录"
              defaultMessage="登录"
            />
          </p>
          <p style={{ fontSize: '14px', color: 'rgba(0,0,0,0.55)' }}>
            <FormattedMessage
              id="login.miniProgramAccount"
              description="微信小程序教育平台账号"
              defaultMessage="微信小程序教育平台账号"
            />
          </p>
        </div>

        <div style={{ height: '50px' }}></div>
        <Input
          errMsg={accountErrMsg}
          desc={
            <FormattedMessage
              id="login.account"
              description="账号"
              defaultMessage="账号"
            />
          }
          descWidth="70"
          placeHolder={intl.formatMessage({
            id: 'login.enterAccount',
            description: '请输入账号',
            defaultMessage: '请输入账号',
          })}
          value={account}
          onChange={(e) => {
            setAccount(e.target.value);
          }}
        />
        <div style={{ height: '24px' }}></div>

        <Input
          errMsg={pwdErrMsg}
          desc={<FormattedMessage id="login.password" />}
          descWidth="70"
          placeHolder={intl.formatMessage({
            id: 'login.enterPassword',
          })}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <div style={{ height: '56px' }}></div>

        <Button
          onClick={handleLogin}
          text={
            <FormattedMessage
              id="login.login"
              description="登录"
              defaultMessage="登录"
            />
          }
          backgroundColor="rgba(7, 193, 96, 1)"
          color="white"
        />
        <div style={{ height: '16px' }}></div>

        {/* <div onClick={() => { ticketManager.clearTicket() }}>{ticketManager.ticket}</div> */}
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: 'rgba(87, 107, 149, 1)',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/registMobile');
            // window.open('https://edu.weixin.qq.com/?action=regist', '_blank')
          }}
        >
          注册
        </p>
      </div>
    </div>
  );
}

export function CopyRightMobile() {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        color: 'rgba(0, 0, 0, 0.3)',
        fontSize: '14px',
      }}
    >
      Copyright © 2018-2024 Tencent. All Rights Reserved.
    </div>
  );
}

export function CopyRightPC() {
  return (
    <div
      style={{
        fontSize: '14px',
        fontWeight: '500',
        color: 'rgba(0,0,0,0.3)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Copyright © 2018-2024 Tencent. All Rights Reserved.
    </div>
  );
}
