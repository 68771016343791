import MultiEndedHoc from '../../components/MultiEndedHoc';
import CompetitionDesktop from './desktop/CompetitionItem';
import CompetitionMobile from './mobile/CompetitionItem';

const competitionItem = () => {
  const WrappedComponent = MultiEndedHoc(CompetitionDesktop, CompetitionMobile);
  return <WrappedComponent />;
};

export default competitionItem;
