import React, { useEffect } from 'react';
import { useDeviceStore } from '../../store';
import { useLocation } from 'react-router-dom';

const RewardList = () => {
  const { setScreenWidth, screenWidth } = useDeviceStore();
  const preURL = process.env.PUBLIC_URL;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      {screenWidth > 780 ? (
        <div
          className="CompetitionContainer"
          style={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <div
            style={{
              transformOrigin: 'top',
              scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}`,
              width: '1224px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
            }}
          >
            <img
              src={`${preURL}/img/rewarded.jpg`}
              style={{ width: '100%' }}
              alt=""
            />
            {/* <img src={`${preURL}/img/reward2.png`} style={{ width: '100%' }} alt="" /> */}
          </div>
        </div>
      ) : (
        <div
          className="CompetitionContainer"
          style={{ width: '100%', backgroundColor: 'rgba(242,242,242,1)' }}
        >
          <img
            src={`${preURL}/img/rewarded.jpg`}
            style={{ width: '100%' }}
            alt=""
          />
          {/* <img src={`${preURL}/img/reward2.png`} style={{ width: '100%' }} alt="" /> */}
        </div>
      )}
    </>
  );
};

export default RewardList;
