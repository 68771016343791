import { useEffect } from 'react';
import '../Official.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeviceStore, useDialogStore } from '../../../store';
import { sendLog } from '../../../config/report';
import { CopyRightPC } from '../../login/mobile/Login';
import { HeadBarPC } from '../../../components/HeadBar/HeadBarPC';
import { FormattedMessage } from 'react-intl';
import OfficialTextBlock from '../../../components/OfficialTextBlock/index';
import { OfficialPhotoBlock } from '../../../components/OfficialPhotoBlock';
import VideoCard from '../../../components/OfficialVideoBlock/OfficialVideoBlock';
import { DIALOG_ID } from '../../../typing/global';

const OfficialDesktop = () => {
  const { screenWidth } = useDeviceStore();

  const navigate = useNavigate();
  const location = useLocation();
  const preURL = process.env.PUBLIC_URL;
  const dialogStore = useDialogStore((state) => state);

  useEffect(() => {
    // 解析URL中的参数，打开注册窗口或下载窗口
    const params: Record<string, string | null> = {};
    const queryString = window.location.search.substring(1);
    const vars = queryString.split('&');

    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair.length === 2) {
        params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
    }
    if (params.action && params.action === 'regist') {
      dialogStore.openDialog(DIALOG_ID.REGIST);
    }

    if (params.action && params.action === 'download') {
      dialogStore.openDialog(DIALOG_ID.DOWNLOAD);
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes('edu.weixin.qq.com/competition')) {
      navigate('/competition');
    } else if (window.location.href.includes('edu.weixin.qq.com/rewardList')) {
      navigate('/rewardList');
    } else if (
      window.location.href.includes('edu.weixin.qq.com/eventDetails')
    ) {
      navigate('/eventDetails');
    }
  }, [location, navigate]);

  const downloadDevtools = () => {
    dialogStore.openDialog(DIALOG_ID.DOWNLOAD);
  };

  return (
    <div
      className="page-official"
      style={{
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <HeadBarPC />

      <div
        style={{
          height: '1012px',
          width: '100%',
          backgroundImage: `url('${preURL}/img/platformBackground.jpg')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          position: 'absolute',
        }}
      ></div>

      <div
        style={{
          transformOrigin: 'top',
          scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}`,
          width: '1224px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '120px',
            marginTop: '33px',
          }}
        >
          <div style={{ marginTop: '188px' }}>
            <div
              className="font-weixin"
              style={{
                fontSize: '40px',
                color: 'rgba(0,0,0,0.9)',
                fontWeight: '400',
                letterSpacing: '3px',
              }}
            >
              <FormattedMessage id="Official.slogan1" />
            </div>
            <div
              style={{
                marginTop: '8px',
                marginBottom: '41px',
                fontSize: '17px',
                color: 'rgba(0,0,0,0.55)',
                fontWeight: '400',
              }}
            >
              <FormattedMessage id="Official.slogan2" />
            </div>
            <div style={{ display: 'flex' }}>
              <div
                onClick={() => {
                  dialogStore.openDialog(DIALOG_ID.REGIST);
                  sendLog({ method: '官网点击去注册' });
                }}
                style={{
                  cursor: 'pointer',
                  width: '152px',
                  height: '52px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0,195,131,1)',
                  fontSize: '20px',
                  fontWeight: '500',
                  color: 'white',
                  position: 'relative',
                }}
              >
                <FormattedMessage id="Official.goRegister" />
              </div>
              <div
                onClick={downloadDevtools}
                style={{
                  cursor: 'pointer',
                  width: '152px',
                  height: '52px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0,0,0,0.03)',
                  fontSize: '20px',
                  fontWeight: '500',
                  color: 'black',
                  position: 'relative',
                  marginLeft: '20px',
                }}
              >
                <FormattedMessage id="Official.downloadTool" />
              </div>
            </div>
          </div>
          <img
            src={`${preURL}/img/platformImage.png`}
            alt=""
            style={{ marginLeft: '50px', width: '640px', height: '480px' }}
          />
        </div>

        <VideoCard />

        <OfficialTextBlock />

        <OfficialPhotoBlock type="desktop" />

        <img
          src={`${preURL}/img/bannerWithoutTitle.png`}
          alt=""
          style={{
            width: '100%',
            cursor: 'pointer',
            marginBottom: '93px',
            borderRadius: '12px',
          }}
          onClick={() => {
            navigate('/competition');
          }}
        />

        <div style={{ marginBottom: '64px' }}>
          <CopyRightPC />
        </div>
      </div>
    </div>
  );
};

export default OfficialDesktop;
