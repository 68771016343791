import { useState } from 'react';
import { ScreenType } from '../typing/global';
import { FormattedMessage } from 'react-intl';
interface OfficialPhotoBlockProps {
  type: ScreenType;
}
export const OfficialPhotoBlock = (props: OfficialPhotoBlockProps) => {
  const preURL = process.env.PUBLIC_URL;
  const [hoveredItemId, setHoverdItemId] = useState<undefined | string>(
    undefined,
  );

  const MobileComponent = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            height: '28px',
            margin: '32px 16px 12px 16px',
          }}
        >
          <div
            style={{
              width: '6px',
              height: '6px',
              backgroundColor: 'rgba(250, 157, 59, 1)',
              marginRight: '6px',
              borderRadius: '1px',
            }}
          ></div>
          <div
            style={{
              fontSize: '15px',
              fontWeight: '500',
              marginRight: '6px',
              lineHeight: '28px',
            }}
          >
            <FormattedMessage
              id="officialPhotoBlock.title"
              defaultMessage="学校教学课件"
              description="学校教学课件"
            />
          </div>
          <div
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: 'rgba(16, 174, 255, 1)',
              alignSelf: 'end',
              borderRadius: '1px',
            }}
          ></div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'scroll',
            scrollbarWidth: 'none',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}
        >
          <div
            onMouseEnter={() => {
              setHoverdItemId('5');
            }}
            onMouseLeave={() => {
              setHoverdItemId(undefined);
            }}
            onClick={() => {
              window.open(
                'https://developers.weixin.qq.com/community/minihome/article/doc/00068ac11dc2e8b04ba0a6c1e66813',
                '_blank',
              );
            }}
            style={{
              cursor: 'pointer',
              position: 'relative',
              flex: '0 0 288px',
              marginRight: '12px',
              borderRadius: '12px',
              border: '0.5px solid rgba(0,0,0,0.05)',
              overflow: 'hidden',
            }}
          >
            {hoveredItemId === '5' && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backgroundColor: 'rgba(0,0,0,0.02)',
                }}
              ></div>
            )}
            <img
              src={`${preURL}/img/case2.jpg`}
              alt=""
            />
            <div style={{ padding: '16px' }}>
              <div
                className="noWrapText"
                style={{
                  height: '21px',
                  fontSize: '15px',
                  fontWeight: '500',
                  color: 'rgba(0,0,0,0.9)',
                }}
              >
                <FormattedMessage
                  id="officialPhotoBlock.subtitle1"
                  description="跨学科学习：小程序+高中数学建模"
                  defaultMessage="跨学科学习：小程序+高中数学建模"
                />
              </div>
              <div
                style={{
                  height: '20px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: 'rgba(0,0,0,0.55)',
                }}
              >
                <span style={{ marginRight: '8px' }}>
                  <FormattedMessage
                    id="officialPhotoBlock.highOne"
                    defaultMessage="高一"
                    description="高一"
                  />
                </span>
                <span>
                  8
                  <FormattedMessage
                    id="officialPhotoBlock.timeLenOrTerm"
                    defaultMessage="课时\学期"
                  />
                </span>
              </div>
              <div style={{ height: '20px', display: 'flex' }}>
                <img
                  src="https://res.wx.qq.com/op_res/oKlOlCPUO0sf9MaluuTBQ3iayBnJhEaj-wbPQPIRzYWxKqRl4WvInqZvpcqQLS9ldyOXzaVPQrdKzhSRziV-Mw"
                  alt=""
                  style={{ width: '20px', height: '20px', marginRight: '8px' }}
                />
                <div
                  className="noWrapText"
                  style={{
                    height: '28px',
                    fontSize: '14px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.55)',
                  }}
                >
                  <FormattedMessage
                    id="officialPhotoBlock.subAuthor1"
                    description="广州市第97中学"
                    defaultMessage="广州市第97中学"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => {
              setHoverdItemId('6');
            }}
            onMouseLeave={() => {
              setHoverdItemId(undefined);
            }}
            onClick={() => {
              window.open(
                'https://developers.weixin.qq.com/community/minihome/article/doc/000ca090d788a02049a0937556b413',
                '_blank',
              );
            }}
            style={{
              cursor: 'pointer',
              position: 'relative',
              flex: '0 0 288px',
              marginRight: '12px',
              borderRadius: '12px',
              border: '0.5px solid rgba(0,0,0,0.05)',
              overflow: 'hidden',
            }}
          >
            {hoveredItemId === '6' && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backgroundColor: 'rgba(0,0,0,0.02)',
                }}
              ></div>
            )}
            <img
              src={`${preURL}/img/case3.jpg`}
              alt=""
            />
            <div style={{ padding: '16px' }}>
              <div
                className="noWrapText"
                style={{
                  height: '21px',
                  fontSize: '15px',
                  fontWeight: '500',
                  color: 'rgba(0,0,0,0.9)',
                }}
              >
                <FormattedMessage
                  id="officialPhotoBlock.subtitle2"
                  description="建设数字校园：校运会小程序"
                  defaultMessage="建设数字校园：校运会小程序"
                />
              </div>
              <div
                style={{
                  height: '20px',
                  marginTop: '9px',
                  marginBottom: '8px',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: 'rgba(0,0,0,0.55)',
                }}
              >
                <span style={{ marginRight: '8px' }}>
                  <FormattedMessage
                    id="officialPhotoBlock.highOne"
                    defaultMessage="高一"
                    description="高一"
                  />
                </span>
                <span>
                  15
                  <FormattedMessage
                    id="officialPhotoBlock.timeLenOrTerm"
                    defaultMessage="课时\学期"
                  />
                </span>
              </div>
              <div style={{ height: '20px', display: 'flex' }}>
                <img
                  src="https://res.wx.qq.com/op_res/0w4AYZat6y9QedpxEVtLxamn56ZwyoNzH6cPk8XGsCy-xvj_KYMc2J0txLthu0r-ZiA-3XAkLPEeEszTqZvxLA"
                  alt=""
                  style={{ width: '20px', height: '20px', marginRight: '8px' }}
                />
                <div
                  className="noWrapText"
                  style={{
                    height: '28px',
                    fontSize: '14px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.55)',
                  }}
                >
                  <FormattedMessage
                    id="officialPhotoBlock.subAuthor2"
                    description="澳门培正中学"
                    defaultMessage="澳门培正中学"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => {
              setHoverdItemId('4');
            }}
            onMouseLeave={() => {
              setHoverdItemId(undefined);
            }}
            onClick={() => {
              window.open(
                'https://developers.weixin.qq.com/community/minihome/article/doc/00040699858158e041a0990ce6d013',
                '_blank',
              );
            }}
            style={{
              cursor: 'pointer',
              position: 'relative',
              flex: '0 0 288px',
              borderRadius: '12px',
              border: '0.5px solid rgba(0,0,0,0.05)',
              overflow: 'hidden',
            }}
          >
            {hoveredItemId === '4' && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backgroundColor: 'rgba(0,0,0,0.02)',
                }}
              ></div>
            )}
            <img
              src={`${preURL}/img/case1.jpg`}
              alt=""
            />
            <div style={{ padding: '16px' }}>
              <div
                className="noWrapText"
                style={{
                  height: '21px',
                  fontSize: '15px',
                  fontWeight: '500',
                  color: 'rgba(0,0,0,0.9)',
                }}
              >
                <FormattedMessage
                  id="officialPhotoBlock.subtitle3"
                  description="连续 4 年开展小程序社团课"
                  defaultMessage="连续 4 年开展小程序社团课"
                />
              </div>
              <div
                style={{
                  height: '20px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  fontSize: '14px',
                  fontWeight: '400',
                  color: 'rgba(0,0,0,0.55)',
                }}
              >
                <span style={{ marginRight: '8px' }}>
                  <FormattedMessage
                    id="officialPhotoBlock.juniorOne"
                    defaultMessage="初一"
                    description="初一"
                  />
                </span>
                <span>
                  10
                  <FormattedMessage
                    id="officialPhotoBlock.timeLenOrTerm"
                    defaultMessage="课时\学期"
                  />
                </span>
              </div>
              <div style={{ height: '20px', display: 'flex' }}>
                <img
                  src="https://res.wx.qq.com/op_res/MMOrezEND3rWP_9Q6Ei8BiNc9IudN8G9KWdbz0kjFhNeNC_45ECC16haYJ-wwFU-VVwAS8LpMxtfLUetymcifg"
                  alt=""
                  style={{ width: '20px', height: '20px', marginRight: '8px' }}
                />
                <div
                  className="noWrapText"
                  style={{
                    height: '28px',
                    fontSize: '14px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.55)',
                  }}
                >
                  <FormattedMessage
                    id="officialPhotoBlock.subAuthor3"
                    description="广州大学附属中学"
                    defaultMessage="广州大学附属中学"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const DesktopComponent = () => {
    return (
      <>
        <div style={{ width: '100%', display: 'flex', height: '34px' }}>
          <div
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: 'rgba(100,103,240,1)',
              marginRight: '6px',
            }}
          ></div>
          <div
            style={{ fontSize: '24px', fontWeight: '500', marginRight: '6px' }}
          >
            <FormattedMessage
              id="officialPhotoBlock.title"
              defaultMessage="学校教学课件"
              description="学校教学课件"
            />
          </div>
          <div
            style={{
              width: '6px',
              height: '6px',
              backgroundColor: 'rgba(16,174,255,1)',
              alignSelf: 'end',
            }}
          ></div>
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '32px',
            marginBottom: '104px',
          }}
        >
          <div
            onMouseEnter={() => {
              setHoverdItemId('5');
            }}
            onMouseLeave={() => {
              setHoverdItemId(undefined);
            }}
            onClick={() => {
              window.open(
                'https://developers.weixin.qq.com/community/minihome/article/doc/00068ac11dc2e8b04ba0a6c1e66813',
                '_blank',
              );
            }}
            style={{
              cursor: 'pointer',
              position: 'relative',
              flex: '1 1 50%',
              marginRight: '17px',
              borderRadius: '12px',
              border: '0.5px solid rgba(0,0,0,0.05)',
              overflow: 'hidden',
            }}
          >
            {hoveredItemId === '5' && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backgroundColor: 'rgba(0,0,0,0.02)',
                }}
              ></div>
            )}
            <img
              src={`${preURL}/img/case2.jpg`}
              alt=""
            />
            <div style={{ padding: '24px' }}>
              <div
                className="noWrapText"
                style={{
                  height: '28px',
                  fontSize: '20px',
                  fontWeight: '500',
                  color: 'rgba(0,0,0,0.9)',
                }}
              >
                <FormattedMessage
                  id="officialPhotoBlock.subtitle1"
                  description="跨学科学习：小程序+高中数学建模"
                  defaultMessage="跨学科学习：小程序+高中数学建模"
                />
              </div>
              <div
                style={{
                  height: '24px',
                  marginTop: '16px',
                  marginBottom: '12px',
                  fontSize: '17px',
                  fontWeight: '400',
                  color: 'rgba(0,0,0,0.55)',
                }}
              >
                <span style={{ marginRight: '8px' }}>
                  <FormattedMessage
                    id="officialPhotoBlock.highOne"
                    defaultMessage="高一"
                    description="高一"
                  />
                </span>
                <span>
                  8
                  <FormattedMessage
                    id="officialPhotoBlock.timeLenOrTerm"
                    defaultMessage="课时\学期"
                  />
                </span>
              </div>
              <div style={{ height: '28px', display: 'flex' }}>
                <img
                  src="https://res.wx.qq.com/op_res/oKlOlCPUO0sf9MaluuTBQ3iayBnJhEaj-wbPQPIRzYWxKqRl4WvInqZvpcqQLS9ldyOXzaVPQrdKzhSRziV-Mw"
                  alt=""
                  style={{ width: '28px', height: '28px', marginRight: '8px' }}
                />
                <div
                  className="noWrapText"
                  style={{
                    height: '28px',
                    fontSize: '17px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.55)',
                  }}
                >
                  <FormattedMessage
                    id="officialPhotoBlock.subAuthor1"
                    description="广州市第97中学"
                    defaultMessage="广州市第97中学"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => {
              setHoverdItemId('6');
            }}
            onMouseLeave={() => {
              setHoverdItemId(undefined);
            }}
            onClick={() => {
              window.open(
                'https://developers.weixin.qq.com/community/minihome/article/doc/000ca090d788a02049a0937556b413',
                '_blank',
              );
            }}
            style={{
              cursor: 'pointer',
              position: 'relative',
              flex: '1 1 50%',
              marginRight: '17px',
              borderRadius: '12px',
              border: '0.5px solid rgba(0,0,0,0.05)',
              overflow: 'hidden',
            }}
          >
            {hoveredItemId === '6' && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backgroundColor: 'rgba(0,0,0,0.02)',
                }}
              ></div>
            )}
            <img
              src={`${preURL}/img/case3.jpg`}
              alt=""
            />
            <div style={{ padding: '24px' }}>
              <div
                className="noWrapText"
                style={{
                  height: '28px',
                  fontSize: '20px',
                  fontWeight: '500',
                  color: 'rgba(0,0,0,0.9)',
                }}
              >
                <FormattedMessage
                  id="officialPhotoBlock.subtitle2"
                  description="建设数字校园：校运会小程序"
                  defaultMessage="建设数字校园：校运会小程序"
                />
              </div>
              <div
                style={{
                  height: '24px',
                  marginTop: '16px',
                  marginBottom: '12px',
                  fontSize: '17px',
                  fontWeight: '400',
                  color: 'rgba(0,0,0,0.55)',
                }}
              >
                <span style={{ marginRight: '8px' }}>
                  <FormattedMessage
                    id="officialPhotoBlock.highOne"
                    defaultMessage="高一"
                    description="高一"
                  />
                </span>
                <span>
                  15
                  <FormattedMessage
                    id="officialPhotoBlock.timeLenOrTerm"
                    defaultMessage="课时\学期"
                  />
                </span>
              </div>
              <div style={{ height: '28px', display: 'flex' }}>
                <img
                  src="https://res.wx.qq.com/op_res/0w4AYZat6y9QedpxEVtLxamn56ZwyoNzH6cPk8XGsCy-xvj_KYMc2J0txLthu0r-ZiA-3XAkLPEeEszTqZvxLA"
                  alt=""
                  style={{ width: '28px', height: '28px', marginRight: '8px' }}
                />
                <div
                  className="noWrapText"
                  style={{
                    height: '28px',
                    fontSize: '17px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.55)',
                  }}
                >
                  <FormattedMessage
                    id="officialPhotoBlock.subAuthor2"
                    description="澳门培正中学"
                    defaultMessage="澳门培正中学"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => {
              setHoverdItemId('4');
            }}
            onMouseLeave={() => {
              setHoverdItemId(undefined);
            }}
            onClick={() => {
              window.open(
                'https://developers.weixin.qq.com/community/minihome/article/doc/00040699858158e041a0990ce6d013',
                '_blank',
              );
            }}
            style={{
              cursor: 'pointer',
              position: 'relative',
              flex: '1 1 50%',
              marginRight: '17px',
              borderRadius: '12px',
              border: '0.5px solid rgba(0,0,0,0.05)',
              overflow: 'hidden',
            }}
          >
            {hoveredItemId === '4' && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  backgroundColor: 'rgba(0,0,0,0.02)',
                }}
              ></div>
            )}
            <img
              src={`${preURL}/img/case1.jpg`}
              alt=""
            />
            <div style={{ padding: '24px' }}>
              <div
                className="noWrapText"
                style={{
                  height: '28px',
                  fontSize: '20px',
                  fontWeight: '500',
                  color: 'rgba(0,0,0,0.9)',
                }}
              >
                <FormattedMessage
                  id="officialPhotoBlock.subtitle3"
                  description="连续 4 年开展小程序社团课"
                  defaultMessage="连续 4 年开展小程序社团课"
                />
              </div>
              <div
                style={{
                  height: '24px',
                  marginTop: '16px',
                  marginBottom: '12px',
                  fontSize: '17px',
                  fontWeight: '400',
                  color: 'rgba(0,0,0,0.55)',
                }}
              >
                <span style={{ marginRight: '8px' }}>
                  <FormattedMessage
                    id="officialPhotoBlock.juniorOne"
                    defaultMessage="初一"
                    description="初一"
                  />
                </span>
                <span>
                  10
                  <FormattedMessage
                    id="officialPhotoBlock.timeLenOrTerm"
                    defaultMessage="课时\学期"
                  />
                </span>
              </div>
              <div style={{ height: '28px', display: 'flex' }}>
                <img
                  src="https://res.wx.qq.com/op_res/MMOrezEND3rWP_9Q6Ei8BiNc9IudN8G9KWdbz0kjFhNeNC_45ECC16haYJ-wwFU-VVwAS8LpMxtfLUetymcifg"
                  alt=""
                  style={{ width: '28px', height: '28px', marginRight: '8px' }}
                />
                <div
                  className="noWrapText"
                  style={{
                    height: '28px',
                    fontSize: '17px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.55)',
                  }}
                >
                  <FormattedMessage
                    id="officialPhotoBlock.subAuthor3"
                    description="广州大学附属中学"
                    defaultMessage="广州大学附属中学"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return props.type === 'mobile' ? <MobileComponent /> : <DesktopComponent />;
};
