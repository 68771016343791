import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import router from './router/router';
import './index.css';
import {
  LanguageStore,
  logout,
  useDeviceStore,
  useDialogStore,
  useLanguageStore,
  UserStore,
  useUserStore,
} from './store';
import { sendLog } from './config/report';
import { IntlProvider, MessageFormatElement } from 'react-intl';
import { getQueryParams } from './config/utils';
import DownloadDialog from './components/Dialog/DownloadDialog';
import RegistDialog from './components/Dialog/RegistDialog';
import { DIALOG_ID } from './typing/global';
import { LoginDialog } from './components/Dialog/LoginDialog';
// 引入语言包
import zhCN from './compiled-lang/zh-cn.json';
import en from './compiled-lang/en.json';

const userManager = useUserStore.getState() as UserStore;
const languageStore = useLanguageStore.getState() as LanguageStore;

sendLog({ method: '10006' });

if (userManager.ticket !== '' && userManager.eduticket_expire_time !== 0) {
  if (Date.now() > userManager.eduticket_expire_time) {
    logout();
  }
}

// 加载语言包
function loadLocaleData(locale: string) {
  switch (locale) {
    case 'zh-cn':
      return zhCN;
    case 'en':
      return en;
    default:
      return zhCN;
  }
}

function App() {
  // 获取url参数，设置语言
  const URLParam = window.location.search.substring(1);
  const paramsObj = URLParam ? getQueryParams(URLParam) : {};
  if (paramsObj.locale) {
    languageStore.setLanguage(paramsObj.locale);
  }

  // 监听语言变化，加载语言包
  const language: string = useLanguageStore((state) => state.language);
  const [message, setMessage] = React.useState<
    Record<string, string> | Record<string, MessageFormatElement[]>
  >();
  // 预留加载语言包的接口，后续切换语言按钮直接更改store里面的language即可，或者直接更改url参数
  useMemo(() => {
    const message = loadLocaleData(language);
    setMessage(message);
  }, [language]);

  // 监听窗口变化，记录屏幕宽度
  const { setScreenWidth } = useDeviceStore();
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 监听弹窗
  const dialogStore = useDialogStore((state) => state);
  return (
    <IntlProvider
      locale={language}
      messages={message}
    >
      <React.StrictMode>
        <RouterProvider router={createBrowserRouter(router)} />
        {dialogStore[DIALOG_ID.DOWNLOAD] && <DownloadDialog />}
        {dialogStore[DIALOG_ID.REGIST] && <RegistDialog />}
        {dialogStore[DIALOG_ID.LOGIN] && <LoginDialog />}
      </React.StrictMode>
    </IntlProvider>
  );
}

// 渲染根组件
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

async function bootstrapApplication() {
  root.render(<App />);
}

bootstrapApplication();
