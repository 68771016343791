import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DIALOG_ID } from './typing/global';

export interface IDeviceState {
  screenWidth: number;
  setScreenWidth: (width: number) => void;
}

export const useDeviceStore = create<IDeviceState>((set) => ({
  screenWidth: window.innerWidth,
  setScreenWidth: (width: number) => {
    set(() => {
      return { screenWidth: width };
    });
  },
}));

export interface UserState {
  account: string;
  ticket: string;
  eduticket_expire_time: number;
  accountType: number;
}
export interface UserStore extends UserState {
  setUserState: ({ account, ticket, eduticket_expire_time }: UserState) => void;
  clearAccount: () => void;
  clearTicket: () => void;
  clearTicketExpireTime: () => void;
}

export const useUserStore = create(
  persist(
    (set) => ({
      account: '',
      ticket: '',
      eduticket_expire_time: 0,
      accountType: -1,
      setUserState: (userState: UserState) => {
        set({ account: userState.account });
        set({ accountType: userState.accountType });
        set({ ticket: userState.ticket });
        set({ eduticket_expire_time: userState.eduticket_expire_time });
      },
      clearAccountType: () => {
        set({ accountType: -1 });
      },
      clearAccount: () => {
        set({ account: '' });
      },
      clearTicket: () => {
        set({ ticket: '' });
      },
      clearTicketExpireTime: () => {
        set({ eduticket_expire_time: 0 });
      },
    }),
    {
      name: 'user-storage',
      getStorage: () => localStorage,
    },
  ),
);

export const login = (
  ticket: string,
  account: string,
  accountType: number,
  eduticket_expire_time: number,
) => {
  const userManager = useUserStore.getState() as UserStore;
  userManager.setUserState({
    ticket,
    account,
    accountType,
    eduticket_expire_time,
  });
};

export const logout = () => {
  const userManager = useUserStore.getState() as UserStore;
  userManager.setUserState({
    ticket: '',
    account: '',
    accountType: -1,
    eduticket_expire_time: 0,
  });
};

export enum UserType {
  SuperAdmin = 1,
  Admin = 2,
  Student = 3,
}

export interface LanguageState {
  language: string;
}
export interface LanguageStore extends LanguageState {
  setLanguage: (language: string) => void;
}
export const useLanguageStore = create<LanguageStore>((set) => ({
  language: 'zh-cn',
  setLanguage: (language: string) => {
    set({ language });
  },
}));

export interface DialogState {
  [key: symbol]: boolean;
}

export interface DialogStore extends DialogState {
  openDialog: (dialogID: symbol) => void;
  closeDialog: (dialogID: symbol) => void;
}

export const useDialogStore = create<DialogStore>((set) => ({
  [DIALOG_ID.DOWNLOAD]: false,
  [DIALOG_ID.REGIST]: false,
  [DIALOG_ID.LOGIN]: false,

  openDialog: (dialogID: symbol) => {
    set((prevState) => {
      return {
        ...prevState,
        [dialogID]: true,
      };
    });
  },

  closeDialog: (dialogID: symbol) => {
    set((prevState) => {
      return {
        ...prevState,
        [dialogID]: false,
      };
    });
  },
}));
